import { defaultIconProps } from 'components/common/icons'
import type { DefaultIconProps } from 'components/common/icons'

export const LinkIcon = ({
  size = defaultIconProps.size,
  className = defaultIconProps.className,
}: DefaultIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={size}
    height={size}
    fill="currentColor"
    className={'rtl:-scale-x-100 ' + className}>
    <path d="M12 22C7.49 22 5.22 22 3.61 20.39C2 18.78 2 16.51 2 12C2 7.49 2 5.22 3.61 3.61C5.14 2.08 6.39 2 10.1 2C10.65 2 11.1 2.45 11.1 3C11.1 3.55 10.65 4 10.1 4C6.46 4 5.94 4.11 5.02 5.02C4 6.04 4 8.03 4 11.99C4 15.95 4 17.94 5.02 18.96C6.05 19.99 8.03 19.99 11.99 19.99C15.95 19.99 17.94 19.99 18.96 18.96C19.87 18.05 19.98 17.53 19.98 13.88C19.98 13.33 20.43 12.88 20.98 12.88C21.53 12.88 21.98 13.33 21.98 13.88C21.98 17.59 21.9 18.84 20.37 20.37C18.76 21.98 16.49 21.98 11.98 21.98L12 22ZM14.93 10.05C14.67 10.05 14.42 9.95 14.22 9.76C13.83 9.37 13.83 8.74 14.22 8.35L18.46 4.13C17.88 4.09 17.07 4.07 15.97 4.09C15.41 4.08 14.96 3.66 14.96 3.1C14.96 2.54 15.39 2.09 15.95 2.09C20.41 2.02 20.9 2.52 21.2 2.82C21.5 3.12 21.99 3.63 21.93 8.07C21.93 8.62 21.48 9.05 20.93 9.05H20.91C20.36 9.05 19.92 8.59 19.93 8.03C19.95 6.92 19.93 6.11 19.89 5.53L15.65 9.76C15.45 9.95 15.2 10.05 14.94 10.05H14.93Z" />
  </svg>
)
