import { FC } from 'react'
import KidsModeLink from '../components/KidsModeLink'
import KidsModeToggleButton from '../components/KidsModeToggleButton'

type KidsModeButtonUIProps = {
  /** The accessible label for the toggle button or link. */
  ariaLabel: string

  className?: string

  /** Boolean indicating if the user is logged in. */
  isAuthenticated: boolean

  /** Boolean indicating if Kids Mode is currently active. */
  isKidsMode: boolean

  /** Callback function to toggle Kids Mode on or off. */
  toggleKidsMode: () => void
}

/**
 * [Presentational component](https://www.patterns.dev/react/presentational-container-pattern#presentational-component) that renders either a `KidsModeLink` or a `KidsModeToggleButton`
 * based on the authentication status of the user. It should receive all relevant props
 * from the container component.
 *
 * - **Unauthenticated**: renders `KidsModeToggleButton`. Clicking this will immediately
 * toggle Kids Mode on or off. Since unauthenticated users can't set a PIN to lock Kids Mode,
 * the toggle will be immediate.
 *
 * - **Authenticated**: renders `KidsModeLink`. Clicking this will link to the Kids Mode settings page,
 * where the user can optionally set a PIN to lock Kids Mode.
 */
const KidsModeButtonUI: FC<KidsModeButtonUIProps> = ({
  ariaLabel,
  className,
  isAuthenticated,
  isKidsMode,
  toggleKidsMode,
}) => {
  if (isAuthenticated) {
    return (
      <KidsModeLink
        ariaLabel={ariaLabel}
        className={className}
        isKidsMode={isKidsMode}
      />
    )
  }
  return (
    <KidsModeToggleButton
      ariaLabel={ariaLabel}
      className={className}
      isKidsMode={isKidsMode}
      onClick={toggleKidsMode}
    />
  )
}

export default KidsModeButtonUI
