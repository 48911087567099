import { getDeviceId } from 'util/user'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import axios from 'axios'
import Bugsnag from '@bugsnag/browser'
import config from 'config'
import { useUser } from 'state/useUser'
import UAParser from 'ua-parser-js'
import create, { SetState } from 'zustand'
import { KindName } from 'graphql/hoopla/generated/graphql'
import { KindExt } from 'types/hoopla'
import { useKinds } from 'state/useKinds'
import {
  AppStartEvent,
  CarouselEvent,
  ClickEvent,
  ConversionEvent,
  Event,
  GridLoadedEvent,
  PageLoadDetails,
  RegistrationEvent,
  SearchEvent,
  TitleDetailsEvent,
  View,
} from './eventTypes'
import EventLabel from './eventLabel'

type pageLoadedState = {
  currentPageLoaded?: string
  setCurrentPageLoaded: (currentPageLoaded: string) => void
}

export type EventActions = {
  sendEvent: (data: Event) => void
  sendClickEvent: (
    data: Omit<ClickEvent, 'category' | 'interactionType'>,
  ) => void
  sendRegistrationEvent: (data: Omit<RegistrationEvent, 'category'>) => void
  sendSearchEvent: (
    data: Omit<SearchEvent, 'category' | 'interactionType'>,
  ) => void
  sendConversionEvent: (data: Omit<ConversionEvent, 'category'>) => void
  sendTitleDetailsEvent: (
    data: Omit<TitleDetailsEvent, 'category' | 'interactionType' | 'label'>,
  ) => void
  sendAppStartEvent: (
    data: Omit<AppStartEvent, 'category' | 'interactionType'>,
  ) => void
  sendCarouselEvent: (
    data: Omit<CarouselEvent, 'category' | 'interactionType'>,
  ) => void
  setPageLoaded: (pageLoaded: View, pageLoadDetails?: PageLoadDetails) => void
  sendGridLoadedEvent: (
    data: Omit<GridLoadedEvent, 'category' | 'interactionType' | 'label'>,
  ) => void
}

const parser = new UAParser()

export const pageLoaded = create<pageLoadedState>(
  (set: SetState<pageLoadedState>) => ({
    currentPageLoaded: undefined,
    setCurrentPageLoaded: (currentPageLoaded) => set({ currentPageLoaded }),
  }),
)

const useEvents = (): EventActions => {
  const getKindByName = useKinds((state) => state.getKindByName)
  const user = useUser((state) => state.user)
  const patron = user?.patrons?.[0]
  const libraryId = patron?.libraryId?.toString()
  const patronId = patron?.id?.toString()
  const {
    currentPageLoaded: currentPageLoadedJSON,
    setCurrentPageLoaded: setCurrentPageLoadedJSON,
  } = pageLoaded()

  // sendEvent is only exported for hootieEvents, do not use for other events
  // please add/update an event action + EventType
  const sendEvent = useCallback(
    async (data: Event) => {
      // to get the kind from url when we have the carousels cached and they load before setting the page.
      const parsePathString = (path: string) => {
        // Remove leading slash if present
        const cleanPath = path.startsWith('/') ? path.substring(1) : path

        // Split the path by '/'
        const segments = cleanPath.split('/')

        return {
          segments: segments,
          section: segments[0] || '',
          category: segments[1] || '',
          fullPath: path,
        }
      }

      const deviceId = getDeviceId()

      let routeKind = parsePathString(window.location.pathname)
      const correctSEOPath = routeKind.category === 'binge'
      if (correctSEOPath) {
        routeKind.category = 'bingePass'
      }
      const kind = getKindByName(
        routeKind?.category.toUpperCase() as KindName,
      ) as KindExt

      const viewer = () => {
        const viewOverwrite = { name: 'BROWSE_KIND', id: kind?.id }
        if (!!kind?.id) {
          return JSON.stringify(viewOverwrite)
        }
        return data.view ? JSON.stringify(data.view) : currentPageLoadedJSON
      }

      const eventData = {
        app: 'WWW',
        appVersion: process.env.REACT_APP_VERSION,
        deviceId,
        deviceModel: parser.getBrowser().name,
        deviceVersion: parser.getBrowser().version,
        libraryId: libraryId ?? undefined,
        os: parser.getOS().name,
        osVersion: parser.getOS().version,
        patronId: patronId ?? undefined,
        timestamp: new Date().getTime(),
        url: window.location.href,
        ...data,
        view: viewer(),
        value: data.value ? JSON.stringify(data.value) : undefined,
      }

      try {
        await analyticsClient.post('/patron/event', eventData)
      } catch (error) {
        Bugsnag.leaveBreadcrumb('Failed to send event', { eventData, error })
      }
    },
    // We have to remove currentPageLoaded because of how agressive Apollo cache is.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [libraryId, patronId, getKindByName],
  )

  const sendClickEvent: EventActions['sendClickEvent'] = useCallback(
    (data) => {
      sendEvent({
        category: 'CLICK',
        interactionType: 'EVENT',
        ...data,
      })
    },
    [sendEvent],
  )

  const sendRegistrationEvent: EventActions['sendRegistrationEvent'] =
    useCallback(
      (data) => {
        sendEvent({
          ...data,
          category: 'REGISTRATION',
          interactionType: data.interactionType ?? 'EVENT',
        })
      },
      [sendEvent],
    )

  const sendSearchEvent: EventActions['sendSearchEvent'] = useCallback(
    (data) => {
      sendEvent({
        category: 'SEARCH',
        interactionType: 'EVENT',
        ...data,
      })
    },
    [sendEvent],
  )

  const sendConversionEvent: EventActions['sendConversionEvent'] = useCallback(
    (data) => {
      sendEvent({
        category: 'CONVERSION',
        ...data,
      })
    },
    [sendEvent],
  )

  const sendCarouselEvent: EventActions['sendCarouselEvent'] = useCallback(
    (data) => {
      if (
        data.label === 'carousel_loaded' &&
        // @ts-ignore ts wants me to prove I have the data before i check if the data is there
        !data.value?.length
      ) {
        return
      }

      if (data.ordinal === undefined) {
        Bugsnag.leaveBreadcrumb(`no ordinal provided on ${data.label}`, {
          value: data.value,
          carouselName: data.sectionHeader,
        })
      }
      sendEvent({
        category: 'CAROUSEL',
        interactionType:
          data.label === 'carousel_loaded' ? 'APP_EVENT' : 'EVENT',
        ...data,
      })
    },
    [sendEvent],
  )

  const setPageLoaded: EventActions['setPageLoaded'] = useCallback(
    (pageLoaded, pageLoadDetails) => {
      // ensures that the page being loaded and value are not sent in an event twice
      // also ensures that a page, such as search, can fire multiple times if the value is updated
      const pageLoadedJSON = JSON.stringify(pageLoaded)
      if (pageLoadedJSON === currentPageLoadedJSON) {
        return
      }

      setCurrentPageLoadedJSON(pageLoadedJSON)
      sendEvent({
        label: pageLoadDetails?.label as EventLabel,
        category: pageLoadDetails?.category,
        interactionType: 'PAGE_LOAD',
        view: pageLoaded,
      })
    },
    [currentPageLoadedJSON, sendEvent, setCurrentPageLoadedJSON],
  )

  const sendTitleDetailsEvent: EventActions['sendTitleDetailsEvent'] =
    useCallback(
      (data) => {
        sendEvent({
          category: 'TITLE_DETAILS',
          interactionType: 'APP_EVENT',
          label: 'title_detail_loaded',
          ...data,
        })
      },
      [sendEvent],
    )

  const sendAppStartEvent: EventActions['sendAppStartEvent'] = useCallback(
    (data) => {
      sendEvent({
        interactionType: 'APP_START',
        ...data,
      })
    },
    [sendEvent],
  )

  const sendGridLoadedEvent: EventActions['sendGridLoadedEvent'] = useCallback(
    (data) => {
      sendEvent({
        category: 'GRID',
        interactionType: 'APP_EVENT',
        ...data,
      })
    },
    [sendEvent],
  )

  return {
    sendEvent,
    setPageLoaded,
    sendClickEvent,
    sendConversionEvent,
    sendCarouselEvent,
    sendGridLoadedEvent,
    sendSearchEvent,
    sendTitleDetailsEvent,
    sendRegistrationEvent,
    sendAppStartEvent,
  }
}

const analyticsClient = axios.create({
  baseURL: config.analyticsApi,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
})

export const EVENT_ERROR = 'ERROR'

export const usePageLoad: EventActions['setPageLoaded'] = (
  page,
  pageLoadDetails,
) => {
  const { setPageLoaded } = useEvents()

  // Store the last page to prevent re-triggering unnecessary events
  const previousPageRef = useRef<{
    page: typeof page
    details?: typeof pageLoadDetails
  } | null>(null)

  const memoizedPage = useMemo(() => page, [page])
  const memoizedPageDetails = useMemo(() => pageLoadDetails, [pageLoadDetails])

  useEffect(() => {
    const previousPage = previousPageRef.current

    // Check if this is a new page load or just updating details
    const isNewPage =
      !previousPage ||
      (previousPage && JSON.stringify(previousPage.page)) !==
        JSON.stringify(memoizedPage)
    const isDetailUpdate =
      previousPage &&
      previousPage.details &&
      previousPage.page.name === memoizedPage.name &&
      JSON.stringify(previousPage.details) !==
        JSON.stringify(memoizedPageDetails)

    if (isNewPage) {
      setPageLoaded(memoizedPage, memoizedPageDetails)
      previousPageRef.current = {
        page: memoizedPage,
        details: memoizedPageDetails,
      }
    } else if (isDetailUpdate) {
      // Only update if page details have changed
      setPageLoaded(memoizedPage, memoizedPageDetails)
      if (previousPageRef.current) {
        previousPageRef.current.details = memoizedPageDetails
      }
    }
  }, [memoizedPage, memoizedPageDetails, setPageLoaded])
}

export default useEvents
