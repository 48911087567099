import Cookies from 'universal-cookie'
import addYears from 'date-fns/addYears'
import { clearKidsModePin } from 'state/useKidsMode'
import { v4 } from 'uuid'
import { api as userApi } from 'api/userApi'
import { AxiosResponse } from 'axios'

import type { User } from 'types/common'

const cookies = new Cookies()

export function isLoggedIn() {
  // warning: this is not reactive
  const token = getCurrentAuthToken()
  return !!token
}

export function getCurrentAuthToken() {
  const token = localStorage.getItem('token')

  if (token) {
    // have a fix in place to move the token out of local storage yet keep the user logged in
    setCurrentAuthToken(true, token)
    localStorage.removeItem('token')
    return token
  }

  return cookies.get('token')
}

// @ts-expect-error - implicit any, later problem :)
export function setCurrentAuthToken(remember, token) {
  if (!token) return false

  if (remember) {
    return cookies.set('token', token, rememberOptions)
  } else {
    return cookies.set('token', token, baseOption)
  }
}

export function clearCurrentAuthToken() {
  return cookies.remove('token', { path: '/' })
}

export function getCurrentPatronId() {
  const activePatronId = localStorage.getItem('activePatronId')

  if (activePatronId) {
    // have a fix in place to move the token out of local storage yet keep the user logged in
    setCurrentPatronId(activePatronId)
    localStorage.removeItem('activePatronId')
    return activePatronId
  }

  return cookies.get('activePatronId')
}

// @ts-expect-error - implicit any, later problem :)
export function setCurrentPatronId(patronId) {
  return cookies.set('activePatronId', patronId, rememberOptions)
}

export function clearCurrentPatronId() {
  return cookies.remove('activePatronId', { path: '/' })
}

export function setDeviceId() {
  const savedDeviceId = cookies.get('hoopla:deviceId')
  if (!savedDeviceId) {
    const deviceId = v4()
    cookies.set('hoopla:deviceId', deviceId, rememberOptions)
    return deviceId
  }
}

export function getDeviceId() {
  return cookies.get('hoopla:deviceId') ?? setDeviceId()
}

const rememberOptions = {
  secure: window.location.hostname.includes('hoopladigital'), //this is to make sure we only use secure cookies for our real envs
  sameSite: true,
  expires: addYears(new Date(), 10),
  path: '/',
}
const baseOption = {
  secure: window.location.hostname.includes('hoopladigital'), //this is to make sure we only use secure cookies for our real envs
  sameSite: true,
  path: '/',
}

export function logout() {
  // clear any patron data
  clearCurrentAuthToken()
  clearCurrentPatronId()
  clearKidsModePin()

  // reload the page
  setTimeout(() => {
    window.location.reload()
  }, 1000)
}

export const fetchCurrentUser = async (): Promise<User | undefined> => {
  if (isLoggedIn()) {
    const userResponse = (await userApi.fetchUser()) as AxiosResponse<User>
    return userResponse.data
  }
  return undefined
}
