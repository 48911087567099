import { defaultIconProps } from 'components/common/icons'
import type { DefaultIconProps } from 'components/common/icons'

export const YoutubeIconV2 = ({
  size = defaultIconProps.size,
  className = defaultIconProps.className,
}: DefaultIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={size}
    height={size}
    stroke="none"
    fill="currentColor"
    className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0001 3.25C9.52637 3.25 7.1731 3.40962 5.04757 3.69767C2.78482 4.0043 1.25006 5.99235 1.25006 8.19868V15.8013C1.25006 18.0076 2.78482 19.9957 5.04757 20.3023C7.1731 20.5904 9.52637 20.75 12.0001 20.75C14.4738 20.75 16.8271 20.5904 18.9526 20.3023C21.2153 19.9957 22.7501 18.0076 22.7501 15.8013V8.19869C22.7501 5.99235 21.2153 4.0043 18.9526 3.69767C16.8271 3.40962 14.4738 3.25 12.0001 3.25ZM10.386 8.35688C10.1543 8.21786 9.86567 8.21422 9.63054 8.34735C9.3954 8.48048 9.25006 8.7298 9.25006 9V15C9.25006 15.2702 9.3954 15.5195 9.63054 15.6526C9.86567 15.7858 10.1543 15.7821 10.386 15.6431L15.386 12.6431C15.6119 12.5076 15.7501 12.2634 15.7501 12C15.7501 11.7366 15.6119 11.4924 15.386 11.3569L10.386 8.35688Z"
    />
  </svg>
)
