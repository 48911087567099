import { defaultIconProps } from 'components/common/icons'
import type { DefaultIconProps } from 'components/common/icons'

export const DownIcon = ({
  size = defaultIconProps.size,
  className = defaultIconProps.className,
}: DefaultIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={size}
    height={size}
    stroke="none"
    fill="currentColor"
    className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.7071 8.29289C19.0977 8.68342 19.0977 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6835 16.0976 11.2929 15.7071L5.2929 9.70716C4.90237 9.31664 4.90237 8.68347 5.29289 8.29295C5.68341 7.90242 6.31658 7.90242 6.7071 8.29294L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289Z"
    />
  </svg>
)
