import { FC } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import BrandedSplashScreen from './BrandedSplashScreen'
import NonBrandedSplashScreen from './NonBrandedSplashScreen'

type LibrarySplashScreenProps = {
  children: JSX.Element
}

const LibrarySplashScreen: FC<LibrarySplashScreenProps> = ({ children }) => {
  const { releaseLibraryBrandingCustomization } = useFlags()

  if (releaseLibraryBrandingCustomization) {
    return <BrandedSplashScreen children={children} />
  }
  return <NonBrandedSplashScreen children={children} />
}

export default LibrarySplashScreen
