import classNames from 'classnames'
import Tooltip from 'components/common/Tooltip/Tooltip'
import KidsModeOff from 'components/common/icons/kids-mode/KidsModeOff'
import KidsModeOn from 'components/common/icons/kids-mode/KidsModeOn'
import { FC, useCallback, useId } from 'react'
import { Link } from 'react-router-dom'

type KidsModalLinkUIProps = {
  /** Accessible label for the link */
  ariaLabel: string

  className?: string

  height?: number

  /** Indicates if Kids Mode is currently enabled */
  isKidsMode: boolean
}

/**
 * [Presentational component](https://www.patterns.dev/react/presentational-container-pattern#presentational-component)
 * that renders a link to the Kids Mode settings page. It should receive all relevant props
 * from the container component.
 */
const KidsModeLinkUI: FC<KidsModalLinkUIProps> = ({
  ariaLabel,
  className,
  isKidsMode,
}) => {
  const descriptionId = useId()

  const renderIcon = useCallback(() => {
    return isKidsMode ? (
      <KidsModeOn height={18} className="text-text-white-1-light" />
    ) : (
      <KidsModeOff height={18} className="text-text-white-1-light" />
    )
  }, [isKidsMode])

  return (
    <div>
      <Tooltip label={ariaLabel}>
        <Link
          to="/my/settings/kids-mode"
          aria-label={ariaLabel}
          aria-describedby={descriptionId}
          className={classNames(
            'inline-flex rounded-lg px-2 pt-[0.5625rem] pb-[0.3125rem] text-text-white-1-light focus-visible:focus-outline',
            className,
          )}>
          {renderIcon()}
        </Link>
      </Tooltip>
      <div id={descriptionId} className="sr-only">
        {isKidsMode ? 'on' : 'off'}
      </div>
    </div>
  )
}

export default KidsModeLinkUI
