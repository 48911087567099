import * as braze from '@braze/web-sdk'
import Bugsnag from '@bugsnag/js'

export const useBraze = () => {
  const initializeBraze = () => {
    braze.initialize('%REACT_APP_BRAZE_API_KEY%', {
      baseUrl: 'https://horus.iad-03.braze.com/api/v3',
    })

    braze.automaticallyShowInAppMessages()

    braze.openSession()
  }

  const changeBrazeUser = (userId: string) => {
    try {
      braze.changeUser(userId)
    } catch (error) {
      Bugsnag.leaveBreadcrumb('Failed to change Braze user', {
        error,
      })
    }
  }

  return { initializeBraze, changeBrazeUser }
}
