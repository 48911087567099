// REACT IMPORTS
import { ReactNode, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

// ABSOLUTE IMPORTS
import LibraryFinder from 'components/registration/LibraryFinder'
import { Stepper } from 'components/registration/ui'
import { useRegistrationState } from 'state/useRegistration'
import { PrimaryButton } from 'ui/buttons/PrimaryButton'

import type { BaseRegistrationProps } from 'types/common'
import type { LibraryExt } from 'types/hoopla'

export default function FindLibrary({
  onBack = () => {},
  onNext = () => {},
}: BaseRegistrationProps) {
  // HOOKS
  const formData = useRegistrationState((state) => state.formData)

  // STATE
  const [library, setLibrary] = useState<LibraryExt | undefined>(
    formData.library,
  )
  const [error, setError] = useState<ReactNode | null>(null)

  function onChange(library: LibraryExt | undefined) {
    setLibrary(library)
    setError(null)
  }
  function onSubmit() {
    if (library) {
      onNext({ library })
    } else {
      setError(
        <FormattedMessage
          id="registration.library.find.error"
          defaultMessage="You must select a library to continue"
        />,
      )
    }
  }

  function onStepperBack() {
    onBack({ library: undefined })
  }

  return (
    <div className="flex h-full flex-col items-center justify-between">
      <div className="flex w-full flex-col items-center">
        <Stepper onBack={onStepperBack} />
        <h1 className="mb-2 text-center text-3xl font-bold">
          <FormattedMessage
            id="registration.find.library.heading"
            defaultMessage="Find Your Library"
          />
        </h1>
        <LibraryFinder
          onChange={onChange}
          library={formData.library}
          pageName="REGISTRATION_FIND_LIBRARY"
        />
        {error && <p className="text-sm text-red-700">{error}</p>}
        <div className="flex flex-col items-center space-y-1">
          <p className="text-center text-gray-500">
            <FormattedMessage
              id="registration.find.library.not.found"
              defaultMessage="Don’t see your library? This may be because they do not offer hoopla yet."
            />
          </p>
          <Link to="/find-library">
            <FormattedMessage
              id="registration.find.library.request.link"
              defaultMessage="Request hoopla here!"
            />
          </Link>
        </div>
      </div>
      <PrimaryButton className="mt-6 h-12 w-11/12" onClick={onSubmit}>
        <FormattedMessage id="registration.button.next" defaultMessage="Next" />
      </PrimaryButton>
    </div>
  )
}
