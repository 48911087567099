import { api as libraryApi } from 'api/librariesApi'
import { AxiosResponse } from 'axios'
import Bugsnag, { NotifiableError } from '@bugsnag/js'
import create, { GetState, SetState, State } from 'zustand'
import {
  AvailabilityFilter,
  LibraryBrandingQueryResponse,
  Maybe,
  Patron,
  SplashConfig,
} from 'types/hoopla'

import { graphql } from 'graphql/hoopla/generated'
import { client } from 'graphql/client'
import config from 'config'
import type { LibraryExt } from 'types/hoopla'

type Rating = {
  id: number
  display: boolean
  rank: number
  rating: string
  ratingSystemId: number
}

export type InternalLibraryRatings = {
  libraryId: number
  comicRatings: Rating[]
  movieRatings: Rating[]
  televisionRatings: Rating[]
}

interface UseLibraryState extends State {
  library?: LibraryExt
  libraryRatings?: InternalLibraryRatings
  librarySplashSettings?: Maybe<SplashConfig> | undefined
  isLibraryBrandingEnabled: boolean
  libraryBrandingColor?: Maybe<string>
  logoUrl?: Maybe<string>

  fetchLibrary: (libraryId: number) => void
  fetchLibraryRatings: (libraryId: number) => void
  fetchLibrarySplash: () => void
  getDefaultAvailabilityFilter: () => AvailabilityFilter
  getLibraryBranding: () => Promise<void>
  teardown: () => void
}
const GetLibrarySplashConfigQueryDocument = graphql(/* gql_hoopla */ `
  query Patron {
    patron {
      library {
        splashConfig {
          textColor
          seconds
          imageName
          displayText
          backgroundColor
        }
      }
    }
  }
`)

const GetLibraryBrandingQueryDocument = graphql(/* gql_hoopla */ `
  query GetLibraryBranding {
    libraryBranding {
      baseColor
      logoFileName
    }
  }
`)

export const useLibrary = create(
  (
    set: SetState<UseLibraryState>,
    get: GetState<UseLibraryState>,
  ): UseLibraryState => ({
    library: undefined,
    libraryRatings: undefined,
    librarySplashSettings: undefined,
    isLibraryBrandingEnabled: false,
    libraryBrandingColor: undefined,
    logoUrl: undefined,
    fetchLibrary: async (libraryId: number) => {
      try {
        const response: AxiosResponse<LibraryExt> =
          await libraryApi.fetchLibrary(libraryId)
        set({ library: response.data })
      } catch (error) {
        Bugsnag.notify(error as NotifiableError)
      }
    },
    fetchLibraryRatings: async (libraryId: number) => {
      try {
        const response: AxiosResponse<InternalLibraryRatings> =
          await libraryApi.fetchLibraryRatings(libraryId)
        set({ libraryRatings: response.data })
      } catch (error) {
        Bugsnag.notify(error as NotifiableError)
      }
    },
    fetchLibrarySplash: async () => {
      const { data } = await client.query<{ patron: Patron }>({
        query: GetLibrarySplashConfigQueryDocument,
        fetchPolicy: 'cache-first',
      })
      const splashConfig = {
        ...data.patron.library.splashConfig,
      }
      set({ librarySplashSettings: splashConfig })
    },
    getDefaultAvailabilityFilter: () => {
      const defaultAvailability = get().library
        ?.defaultAvailabilityFilter as AvailabilityFilter

      const libraryHasAvailability =
        Object.values(AvailabilityFilter).includes(defaultAvailability)

      if (libraryHasAvailability) return defaultAvailability

      return get().library?.enableTitleRequests
        ? AvailabilityFilter.AllHooplaTitles
        : AvailabilityFilter.AllTitles
    },
    getLibraryBranding: async () => {
      const { data } = await client.query<{
        libraryBranding: LibraryBrandingQueryResponse
      }>({
        query: GetLibraryBrandingQueryDocument,
        fetchPolicy: 'cache-first',
      })

      const baseColor = data?.libraryBranding?.baseColor
      const logoFileName = data?.libraryBranding?.logoFileName

      // Return a null object when:
      //  enabled is false
      //  base color is false, not set, not right
      //  image file has not been set (or are not valid)
      //
      // Else return an object:
      //  baseColor as a hex color code
      //  logoFileName
      //  but excludes enabled and id
      const isLibraryBrandingEnabled = () => {
        if (data?.libraryBranding != null) {
          return true
        }
        return false
      }

      set({
        isLibraryBrandingEnabled: isLibraryBrandingEnabled(),
        libraryBrandingColor: baseColor ?? '',
        logoUrl: logoFileName
          ? `${config.librarySplashUrl}${logoFileName}`
          : '',
      })
    },
    teardown: () => {
      set({
        library: undefined,
        libraryRatings: undefined,
        librarySplashSettings: undefined,
        isLibraryBrandingEnabled: false,
        libraryBrandingColor: undefined,
        logoUrl: undefined,
      })
    },
  }),
)
