import { useNavigate } from 'react-router-dom'
import { useCallback, useState } from 'react'

import { clearCurrentAuthToken } from '../util/user'
import { client } from '../graphql/client'
import { useKinds } from './useKinds'
import { useUser } from './useUser'
import { useLibrary } from './useLibrary'

export const LOGIN_STATUS_SUCCESS: string = 'SUCCESS'

interface UseLoginResponse {
  completeLogin: (
    email: string,
    password: string,
    remember?: boolean,
  ) => Promise<{ status: string; message: string }>
  initializePatron: () => Promise<void>
  isInitializingPatron: boolean
}

export function useLogin(): UseLoginResponse {
  const navigate = useNavigate()
  const refetchKinds = useKinds((state) => state.refetch)
  const getLibraryBranding = useLibrary((state) => state.getLibraryBranding)

  const [isInitializingPatron, setInitializingPatron] = useState<boolean>(false)

  const authenticate = useUser((state) => state.authenticate)
  const getUser = useUser((state) => state.getUser)

  const fetchLibrary = useLibrary((state) => state.fetchLibrary)
  const fetchLibraryRatings = useLibrary((state) => state.fetchLibraryRatings)
  const fetchLibrarySplash = useLibrary((state) => state.fetchLibrarySplash)

  const initializePatron = useCallback(
    async function initializePatron(): Promise<void> {
      setInitializingPatron(true)

      try {
        const user = await getUser()

        if (user) {
          await Promise.all([
            fetchLibrary(user.patrons[0].libraryId),
            fetchLibrarySplash(),
            fetchLibraryRatings(user.patrons[0].libraryId),
            refetchKinds(),
            getLibraryBranding(),
          ])
        }
      } catch (error: any) {
        const status: number = error?.response?.status

        if (status === 401) {
          // the token might be expired.
          // clear the token, then try to login.
          clearCurrentAuthToken()

          navigate('/login')
        }
      } finally {
        setInitializingPatron(false)
      }
    },
    [
      getUser,
      fetchLibrary,
      fetchLibrarySplash,
      fetchLibraryRatings,
      getLibraryBranding,
      refetchKinds,
      navigate,
    ],
  )

  const completeLogin = useCallback(
    async function completeLogin(
      email: string,
      password: string,
      remember: boolean = false,
    ): Promise<{ status: string; message: string }> {
      const { status, message } = await authenticate(email, password, remember)

      if (status === LOGIN_STATUS_SUCCESS) {
        // reset GQL store to ensure all cached data is
        // contextual to current patron
        await client.resetStore()

        // fetch patron, library, and related data
        await initializePatron()
      }

      return { status, message }
    },
    [authenticate, initializePatron],
  )

  return {
    completeLogin,
    initializePatron,
    isInitializingPatron,
  }
}
