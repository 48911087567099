import { getCurrentAuthToken, getCurrentPatronId } from 'util/user'
import config from 'config'
import { useNavigate } from 'react-router-dom'
import { useRef, useEffect } from 'react'
import { isKidsMode } from 'state/useKidsMode'
import { api as playerApi } from 'api/player'
import licenseApi from 'api/licenseApi'
import create, { SetState, State } from 'zustand'
import sortBy from 'lodash/sortBy'
import findIndex from 'lodash/findIndex'
import { buildStylesForSettings } from 'components/players/chromecast/util'
import Bugsnag from '@bugsnag/browser'
import { useCurrentlyPlaying } from 'state/useCurrentlyPlaying'
import { KindName } from 'types/hoopla'
import { fetchTitleDetail } from './useTitleDetail'

export enum PlayerState {
  PLAYING = 'PLAYING',
  PAUSED = 'PAUSED',
  LOADING = 'LOADING',
}

type PlayChromecastData = {
  token: string
  episode: number
  titleId: string
}

export function useChromecast() {
  const isChromecastConnected = useChromecastState(
    (state) => state.isChromecastConnected,
  )
  const isAvailable = useChromecastState((state) => state.isAvailable)
  const position = useChromecastState((state) => state.position)
  const duration = useChromecastState((state) => state.duration)
  const playerState = useChromecastState((state) => state.playerState)
  const textTracks = useChromecastState((state) => state.textTracks)
  const trackId = useChromecastState((state) => state.trackId)

  const setAvailable = useChromecastState((state) => state.setAvailable)
  const setChromecastConnected = useChromecastState(
    (state) => state.setChromecastConnected,
  )
  const setDuration = useChromecastState((state) => state.setDuration)
  const setPlayerState = useChromecastState((state) => state.setPlayerState)
  const setPosition = useChromecastState((state) => state.setPosition)
  const setTextTracks = useChromecastState((state) => state.setTextTracks)
  const setTrackId = useChromecastState((state) => state.setTrackId)
  const teardown = useChromecastState((state) => state.teardown)
  const remotePlayerController =
    useRef<cast.framework.RemotePlayerController | null>(null)

  const {
    clearCurrentlyPlayingTitle,
    currentlyPlayingTitle,
    currentEpisode,
    setCurrentlyPlayingTitle,
  } = useCurrentlyPlaying()

  const navigate = useNavigate()

  useEffect(() => {
    // in order for the event listeners to get access to the changed titles or tracks we need to put them in this useEffect
    if (isAvailable) {
      addEventListeners()
      return () => {
        removeEventListeners()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAvailable, currentlyPlayingTitle])

  function chromecastConnectionCheck() {
    const castApiSrc =
      'https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1'

    if (!document.querySelector(`script[src="${castApiSrc}"]`)) {
      // `window.__onGCastApiAvailable` is the global function that the
      // cast api script will call when it's ready. It needs to be defined
      // before the cast api script is loaded.
      window['__onGCastApiAvailable'] = (
        isChromecastAvailable: boolean,
        errorInfo,
      ) => {
        if (isChromecastAvailable) {
          setTimeout(() => {
            // we need to wait a second because sometimes google lies and cast isn't actually available right away
            if (!window.chrome?.cast) {
              // framework was not fully loaded`
              return false
            }

            const castFramework = window.cast?.framework
            const chrome = window.chrome
            castFramework.CastContext.getInstance().setOptions({
              receiverApplicationId: config.chromecastAppId,
              autoJoinPolicy: chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED,
            })
            const remotePlayer = new castFramework.RemotePlayer()
            remotePlayerController.current =
              new castFramework.RemotePlayerController(remotePlayer)
            setAvailable(true)
          }, 1000)
        } else {
          Bugsnag.notify(new Error(errorInfo))
        }
      }

      // Once window.__onGCastApiAvailable is defined, we can load the cast api.
      const script = document.createElement('script')
      script.src = castApiSrc
      document.body.appendChild(script)
    }
  }

  function addEventListeners() {
    const castFramework = window.cast?.framework
    remotePlayerController?.current?.addEventListener(
      castFramework.RemotePlayerEventType.IS_CONNECTED_CHANGED,
      onConnectionChange,
    )
    remotePlayerController?.current?.addEventListener(
      castFramework.RemotePlayerEventType.CURRENT_TIME_CHANGED,
      onTimeChange,
    )
    remotePlayerController?.current?.addEventListener(
      castFramework.RemotePlayerEventType.MEDIA_INFO_CHANGED,
      onMediaInfoChanged,
    )
    remotePlayerController?.current?.addEventListener(
      castFramework.RemotePlayerEventType.DURATION_CHANGED,
      onDurationChanged,
    )
    remotePlayerController?.current?.addEventListener(
      castFramework.RemotePlayerEventType.IS_PAUSED_CHANGED,
      onIsPausedChange,
    )
  }

  function removeEventListeners() {
    const castFramework = window.cast?.framework
    remotePlayerController?.current?.removeEventListener(
      castFramework.RemotePlayerEventType.IS_CONNECTED_CHANGED,
      onConnectionChange,
    )
    remotePlayerController?.current?.removeEventListener(
      castFramework.RemotePlayerEventType.CURRENT_TIME_CHANGED,
      onTimeChange,
    )
    remotePlayerController?.current?.removeEventListener(
      castFramework.RemotePlayerEventType.MEDIA_INFO_CHANGED,
      onMediaInfoChanged,
    )
    remotePlayerController?.current?.removeEventListener(
      castFramework.RemotePlayerEventType.DURATION_CHANGED,
      onDurationChanged,
    )
    remotePlayerController?.current?.removeEventListener(
      castFramework.RemotePlayerEventType.IS_PAUSED_CHANGED,
      onIsPausedChange,
    )
  }

  function onConnectionChange(e: any) {
    if (e.value) {
      // user is connected see if there is already a title playing on this connections
      const castSession =
        window.cast.framework.CastContext.getInstance().getCurrentSession()
      if (currentlyPlayingTitle && !castSession?.getMediaSession()) {
        // the user already playing a title in the player and initiated chromecast
        navigate(`/title/${currentlyPlayingTitle}`)
        playTitle(currentlyPlayingTitle, currentEpisode || 0, false)
      } else if (castSession?.getMediaSession() && !currentlyPlayingTitle) {
        // the user already has a session but we don't have the title set in state
        // this can happen if the user reloads www
        const metadata = castSession?.getMediaSession()?.media.metadata
        const { titleId, contentId } = metadata
        fetchTitleAndSetPlayer(titleId, contentId)
      }
    } else {
      teardown()
      clearCurrentlyPlayingTitle()
    }

    setChromecastConnected(e.value)
  }
  async function playTitle(
    titleId: string,
    track: number,
    resume: boolean = false,
  ) {
    if (window.cast?.framework) {
      const response = await fetchTitleDetail(titleId)
      const title = response.data.title
      setCurrentlyPlayingTitle(title.title, titleId, title.kind.name, track)

      try {
        // It looks like resume stops us from making an additional load request to chromecast
        if (!resume) {
          const content =
            title.kind?.name === KindName.Television
              ? title?.episodes[track]
              : title
          const patronId = getCurrentPatronId()
          const circId = content?.circulation?.id
          const { mediaKey } = content

          const response = await licenseApi.fetchUpFrontAuthToken(
            mediaKey,
            patronId,
            circId,
          )
          loadRequest({ token: response?.data, episode: track, titleId })

          playerApi.addPlayCount(circId, content.id)
        }
      } catch (e: any) {
        Bugsnag.notify(new Error(e))
      }
    }
  }

  async function loadRequest({ token, episode, titleId }: PlayChromecastData) {
    const response = await fetchTitleDetail(titleId)
    const title = response.data.title
    const content =
      title.kind.name === KindName.Television ? title.episodes[episode] : title
    const { circulation, mediaKey } = content
    const cast = window.chrome.cast

    const castSession =
      window.cast.framework.CastContext.getInstance().getCurrentSession()

    const positionResponse = await playerApi.getPosition(content.id)
    const { seconds } = positionResponse.data

    const mediaInfo = new cast.media.MediaInfo(
      `${config.dashUrl}/${mediaKey}/Manifest.mpd`,
      'application/dash+xml',
    )
    const customData = {
      userId: circulation.patron.id,
      sessionId: circulation.id,
      merchant: 'hoopla',
      environment: 'PRODUCTION',
      drmProtected: true,
      preferredAudioLanguage: 'en',
      preferredTextLanguage: 'en',
      authToken: token,
    }

    mediaInfo.metadata = new cast.media.GenericMediaMetadata()
    mediaInfo.metadata = {
      ...mediaInfo.metadata,
      titleId: title.id,
      kidsMode: isKidsMode(),
      patronId: circulation.patron.id,
      patronAuthToken: getCurrentAuthToken(),
      contentId: content.id,
      senderApp: 'WWW',
      title:
        title.kind.name === KindName.Television ? content.title : title.title,
      subtitle: title.kind.name === KindName.Television && title.title,
      images: [
        {
          url: `${config.titleArtUrl}/${title.artKey}_270.jpeg`,
        },
      ],
    }
    mediaInfo.streamType = cast.media.StreamType.BUFFERED
    mediaInfo.customData = {
      drmtoday: customData,
      drmProtected: true,
    }

    const request = new cast.media.LoadRequest(mediaInfo)
    if (seconds) request.currentTime = seconds

    castSession?.loadMedia(request)
  }

  function onTimeChange(e: any) {
    setPosition(Math.floor(e.value))
  }

  function onDurationChanged(e: any) {
    if (e.value !== 0) {
      setDuration(e.value)
      setPlayerState(PlayerState.PLAYING)
    }
  }
  function togglePlay() {
    const castFramework = window.cast?.framework
    const remotePlayer = new castFramework.RemotePlayer()
    const remotePlayerController = new castFramework.RemotePlayerController(
      remotePlayer,
    )
    remotePlayerController.playOrPause()
  }

  function onIsPausedChange(e: any) {
    if (e.value) {
      setPlayerState(PlayerState.PAUSED)
    } else {
      setPlayerState(PlayerState.PLAYING)
    }
  }

  function onSeek(value: number) {
    const castSession =
      window.cast.framework.CastContext.getInstance().getCurrentSession()

    if (castSession) {
      const seekRequest = new window.chrome.cast.media.SeekRequest()
      seekRequest.currentTime = value
      castSession.getMediaSession()?.seek(
        seekRequest,
        () => {},
        () => {},
      )
      setPosition(value)
    }
  }

  function onMediaInfoChanged(e: any) {
    if (e.value) {
      const tracks = e.value.tracks
      if (tracks) {
        const filteredTracks: any[] = sortBy(
          tracks.filter((track: any) => track.trackContentType === 'text/vtt'),
          ['trackId'],
        )

        setTextTracks(filteredTracks)
      }

      const { titleId, contentId } = e.value.metadata
      if (titleId !== currentlyPlayingTitle && currentlyPlayingTitle) {
        fetchTitleAndSetPlayer(titleId, contentId)
      }
    }
  }

  function changeTrack(trackId: number | null) {
    setTrackId(trackId)
    const castSession =
      window.cast.framework.CastContext.getInstance().getCurrentSession()
    const trackIds = trackId ? [trackId] : []
    let textTrackStyle = buildStylesForSettings(
      new window.chrome.cast.media.TextTrackStyle(),
    )
    const tracksInfoRequest =
      new window.chrome.cast.media.EditTracksInfoRequest(
        trackIds,
        textTrackStyle,
      )
    castSession?.getMediaSession()?.editTracksInfo(
      tracksInfoRequest,
      () => {},
      () => {},
    )
  }

  function changeTrackStyle() {
    if (trackId) {
      const castSession =
        window.cast.framework.CastContext.getInstance().getCurrentSession()
      var textTrackStyle = buildStylesForSettings(
        new window.chrome.cast.media.TextTrackStyle(),
      )
      var tracksInfoRequest =
        new window.chrome.cast.media.EditTracksInfoRequest(
          [trackId],
          textTrackStyle,
        )
      castSession?.getMediaSession()?.editTracksInfo(
        tracksInfoRequest,
        () => {},
        () => {},
      )
    }
  }

  async function fetchTitleAndSetPlayer(titleId: string, contentId: string) {
    const response = await fetchTitleDetail(titleId)
    const title = response?.data?.title
    const index =
      title?.kind?.name === KindName.Television
        ? findIndex(title.episodes, { id: contentId })
        : 0
    playTitle(title.id, index, true)
    setPlayerState(PlayerState.PLAYING)
  }

  return {
    changeTrackStyle,
    duration,
    isChromecastConnected,
    chromecastConnectionCheck,
    playerState,
    playTitle,
    position,
    togglePlay,
    onSeek,
    textTracks,
    changeTrack,
  }
}

interface ChromecastState extends State {
  currentlyPlayingId: number | null
  duration: number
  isAvailable: boolean
  isChromecastConnected: boolean
  playerState: PlayerState
  position: number
  setChromecastConnected: (isChromecastConnected: boolean) => void
  setAvailable: (isAvailable: boolean) => void
  setCurrentlyPlayingId: (currentlyPlayingId: number) => void
  setDuration: (duration: number) => void
  setPlayerState: (playerState: PlayerState) => void
  setPosition: (position: number) => void
  setTextTracks: (textTracks: any[]) => void
  setTrackId: (trackId: number | null) => void
  textTracks: any[]
  trackId: number | null
  teardown: () => void
}

const useChromecastState = create<ChromecastState>(
  (set: SetState<ChromecastState>) => ({
    currentlyPlayingId: null,
    duration: 0,
    isAvailable: false,
    isChromecastConnected: false,
    playerState: PlayerState.LOADING,
    position: 0,
    setAvailable: (isAvailable: boolean) => set({ isAvailable }),
    setChromecastConnected: (isChromecastConnected: boolean) =>
      set({ isChromecastConnected }),
    setDuration: (duration: number) => set({ duration }),
    setCurrentlyPlayingId: (currentlyPlayingId: number) =>
      set({ currentlyPlayingId }),
    setPlayerState: (playerState: PlayerState) => set({ playerState }),
    setPosition: (position: number) => set({ position }),
    setTextTracks: (textTracks: any[]) => set({ textTracks }),
    setTrackId: (trackId: number | null) => set({ trackId }),
    textTracks: [],
    teardown: () =>
      set({
        currentlyPlayingId: null,
        duration: 0,
        isChromecastConnected: false,
        playerState: PlayerState.LOADING,
        position: 0,
        textTracks: [],
      }),
    trackId: null,
  }),
)
