import { Language } from 'types/hoopla'
import { client } from 'graphql/client'
import { graphql } from 'graphql/hoopla/generated'
import keyBy from 'lodash/keyBy'
import create, { SetState, State } from 'zustand'

interface LanguageState extends State {
  languages: Language[]
  languagesById: { [key: string]: Language }
  getLanguages: () => void
}

const GetLanguageQueryDocument = graphql(/* gql_hoopla */ `
  query GetLanguageQuery {
    languages {
      id
      label
    }
  }
`)

export const useLanguages = create(
  (set: SetState<LanguageState>): LanguageState => ({
    languagesById: {},
    languages: [],
    getLanguages: async () => {
      const { data } = await client.query({
        query: GetLanguageQueryDocument,
        fetchPolicy: 'cache-first',
      })
      const languages = data.languages as Language[]
      set({ languages, languagesById: keyBy(languages, 'id') })
    },
  }),
)
