// REACT IMPORTS
import { FormattedMessage } from 'react-intl'

// ABSOLUTE IMPORTS
import { IconWeight, Settings } from 'components/common/icons'
import KidsModeButton from 'components/common/KidsModeButton'
import HooplaWhite from 'images/Hoopla_white.png'
import { LayoutContainer } from 'ui/layout/LayoutContainer'

function Header() {
  return (
    <header role="banner">
      <section className="hidden bg-hoopla-blue pb-1 sm:block">
        <LayoutContainer className="animate-pulse">
          <ul
            aria-label="main"
            className="flex flex-row items-start space-x-4 py-8 pt-3">
            <li className="pt-1">
              <img
                style={{ width: 85, height: 34 }}
                className="block w-full"
                aria-hidden="true"
                src={HooplaWhite}
                alt="hoopla logo"
              />
            </li>

            <li className="-mx-1.5 flex h-10 items-center rounded px-2 text-sm font-semibold uppercase text-slate-400 filter hover:bg-hoopla-blue-dark hover:text-secondary-pale focus:bg-hoopla-blue-dark focus:text-secondary-pale active:bg-hoopla-blue-dark active:text-secondary-pale">
              <FormattedMessage id="header.nav.my" defaultMessage="My hoopla" />
            </li>

            <li className="-mx-1.5 flex h-10 items-center rounded px-2 text-sm font-semibold uppercase text-slate-400 filter hover:bg-hoopla-blue-dark hover:text-secondary-pale focus:bg-hoopla-blue-dark focus:text-secondary-pale active:bg-hoopla-blue-dark active:text-secondary-pale">
              <FormattedMessage
                id="header.nav.browse"
                defaultMessage="Browse"
                description="label for browse link"
              />
            </li>

            <li style={{ flexGrow: 1 }}>
              <div className="relative m-0 flex h-10 w-full flex-1 rounded !border-none bg-slate-400 py-2 pr-5 pl-2" />
            </li>

            <li className="pt-2">
              <KidsModeButton />
            </li>

            <li className="pt-2 text-slate-400">
              <Settings size={30} weight={IconWeight.regular} />
            </li>
          </ul>
        </LayoutContainer>
      </section>
    </header>
  )
}

export default Header
