export const AppStoreBadge = () => (
  <svg
    width="129"
    height="41"
    viewBox="0 0 129 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3104_5011)">
      <path
        d="M29.6557 19.366C29.6259 16.532 31.9811 15.1546 32.0904 15.0853C30.7588 13.1431 28.6918 12.8854 27.9664 12.8656C26.2274 12.6873 24.548 13.8962 23.6636 13.8962C22.7792 13.8962 21.3979 12.8755 19.9371 12.9053C18.049 12.935 16.2802 14.025 15.3163 15.7194C13.3189 19.1678 14.8095 24.2413 16.7274 27.0257C17.6814 28.3932 18.8043 29.9192 20.265 29.8597C21.696 29.8003 22.2326 28.9481 23.9617 28.9481C25.6908 28.9481 26.1777 29.8597 27.6683 29.83C29.2086 29.8003 30.1725 28.4625 31.0966 27.0753C32.1997 25.5096 32.6469 23.9638 32.6667 23.8845C32.627 23.8746 29.6756 22.745 29.6458 19.3561L29.6557 19.366Z"
        fill="currentColor"
      />
      <path
        d="M26.8337 11.0325C27.6088 10.0714 28.1355 8.76337 27.9865 7.43555C26.8735 7.48509 25.4723 8.20846 24.6674 9.14982C23.9519 9.98218 23.3259 11.3397 23.4849 12.618C24.737 12.7072 26.0288 11.9838 26.8337 11.0425V11.0325Z"
        fill="currentColor"
      />
      <path
        d="M46.848 26.5499H42.1476L41.0148 29.8794H39.0273L43.4792 17.5723H45.5462L49.9981 29.8794H47.9709L46.838 26.5499H46.848ZM42.6346 25.014H46.361L44.5226 19.6135H44.473L42.6346 25.014Z"
        fill="currentColor"
      />
      <path
        d="M59.6278 25.3905C59.6278 28.175 58.1273 29.9685 55.8716 29.9685C54.5897 29.9685 53.5761 29.3938 53.0394 28.4029H52.9997V32.8421H51.1514V20.9017H52.9401V22.398H52.9699C53.4866 21.4368 54.5797 20.8125 55.8318 20.8125C58.1174 20.8125 59.6179 22.616 59.6179 25.3905H59.6278ZM57.7298 25.3905C57.7298 23.5771 56.7858 22.3781 55.3548 22.3781C53.9239 22.3781 52.9898 23.597 52.9898 25.3905C52.9898 27.184 53.9437 28.4128 55.3548 28.4128C56.7659 28.4128 57.7298 27.2237 57.7298 25.3905Z"
        fill="currentColor"
      />
      <path
        d="M69.5253 25.3905C69.5253 28.175 68.0248 29.9685 65.769 29.9685C64.4871 29.9685 63.4735 29.3938 62.9369 28.4029H62.8972V32.8421H61.0488V20.9017H62.8375V22.398H62.8673C63.3841 21.4368 64.4772 20.8125 65.7293 20.8125C68.0148 20.8125 69.5154 22.616 69.5154 25.3905H69.5253ZM67.6273 25.3905C67.6273 23.5771 66.6832 22.3781 65.2523 22.3781C63.8213 22.3781 62.8872 23.597 62.8872 25.3905C62.8872 27.184 63.8412 28.4128 65.2523 28.4128C66.6634 28.4128 67.6273 27.2237 67.6273 25.3905Z"
        fill="currentColor"
      />
      <path
        d="M76.0736 26.4499C76.2127 27.6687 77.3953 28.4714 79.025 28.4714C80.6547 28.4714 81.6981 27.6687 81.6981 26.5688C81.6981 25.6176 81.0224 25.0428 79.4225 24.6465L77.8226 24.26C75.5569 23.715 74.5035 22.6547 74.5035 20.9405C74.5035 18.8199 76.3618 17.3633 78.9952 17.3633C81.6285 17.3633 83.3874 18.8199 83.4471 20.9405H81.5789C81.4696 19.7117 80.446 18.9686 78.9654 18.9686C77.4847 18.9686 76.4612 19.7216 76.4612 20.8116C76.4612 21.6836 77.1071 22.189 78.6971 22.5854L80.0585 22.9223C82.5925 23.5168 83.6458 24.5375 83.6458 26.331C83.6458 28.6299 81.8074 30.0766 78.8859 30.0766C76.1531 30.0766 74.3048 28.6696 74.1855 26.44H76.0736V26.4499Z"
        fill="currentColor"
      />
      <path
        d="M87.6407 18.7793V20.8998H89.3499V22.3565H87.6407V27.3011C87.6407 28.074 87.9785 28.4308 88.7338 28.4308C88.9226 28.4308 89.2207 28.401 89.3399 28.3911V29.8378C89.1313 29.8874 88.7238 29.927 88.3164 29.927C86.4979 29.927 85.7824 29.2433 85.7824 27.5092V22.3664H84.4707V20.9098H85.7824V18.7892H87.6407V18.7793Z"
        fill="currentColor"
      />
      <path
        d="M90.334 25.3888C90.334 22.5647 92.0034 20.791 94.5971 20.791C97.1907 20.791 98.8601 22.5647 98.8601 25.3888C98.8601 28.2129 97.2106 29.9867 94.5971 29.9867C91.9836 29.9867 90.334 28.2228 90.334 25.3888ZM96.9919 25.3888C96.9919 23.4566 96.0976 22.3071 94.607 22.3071C93.1164 22.3071 92.2221 23.4566 92.2221 25.3888C92.2221 27.3211 93.1164 28.4706 94.607 28.4706C96.0976 28.4706 96.9919 27.3409 96.9919 25.3888Z"
        fill="currentColor"
      />
      <path
        d="M100.399 20.8999H102.158V22.4259H102.198C102.476 21.4151 103.301 20.8008 104.364 20.8008C104.633 20.8008 104.851 20.8404 105 20.8701V22.5943C104.851 22.5349 104.533 22.4853 104.176 22.4853C102.983 22.4853 102.248 23.288 102.248 24.5464V29.8676H100.399V20.8999Z"
        fill="currentColor"
      />
      <path
        d="M113.507 27.2418C113.259 28.8669 111.669 29.9867 109.632 29.9867C107.018 29.9867 105.389 28.2427 105.389 25.4285C105.389 22.6143 107.018 20.791 109.552 20.791C112.086 20.791 113.607 22.4954 113.607 25.2204V25.8546H107.257V25.9636C107.257 27.4995 108.221 28.5003 109.682 28.5003C110.705 28.5003 111.51 28.0147 111.758 27.2418H113.507ZM107.267 24.5565H111.768C111.729 23.1791 110.844 22.2774 109.562 22.2774C108.28 22.2774 107.366 23.1989 107.267 24.5565Z"
        fill="currentColor"
      />
      <path
        d="M42.4043 8.30483C44.1632 8.30483 45.1966 9.38492 45.1966 11.2379C45.1966 13.0909 44.1731 14.2106 42.4043 14.2106H40.2578V8.29492H42.4043V8.30483ZM41.182 13.3783H42.295C43.5371 13.3783 44.2526 12.6054 44.2526 11.2478C44.2526 9.89029 43.5272 9.13719 42.295 9.13719H41.182V13.3783Z"
        fill="currentColor"
      />
      <path
        d="M46.2295 11.9808C46.2295 10.544 47.0344 9.66211 48.3362 9.66211C49.638 9.66211 50.4429 10.5341 50.4429 11.9808C50.4429 13.4276 49.638 14.2996 48.3362 14.2996C47.0344 14.2996 46.2295 13.4276 46.2295 11.9808ZM49.5386 11.9808C49.5386 11.0097 49.1014 10.4449 48.3362 10.4449C47.571 10.4449 47.1338 11.0097 47.1338 11.9808C47.1338 12.9519 47.5611 13.5167 48.3362 13.5167C49.1113 13.5167 49.5386 12.9519 49.5386 11.9808Z"
        fill="currentColor"
      />
      <path
        d="M56.0585 14.2208H55.1442L54.2201 10.931H54.1505L53.2264 14.2208H52.3221L51.0898 9.76172H51.9842L52.7891 13.1704H52.8587L53.7828 9.76172H54.6275L55.5517 13.1704H55.6212L56.4162 9.76172H57.3006L56.0684 14.2208H56.0585Z"
        fill="currentColor"
      />
      <path
        d="M58.3232 9.75115H59.1778V10.4646H59.2474C59.466 9.96914 59.9132 9.67188 60.579 9.67188C61.5727 9.67188 62.1292 10.2664 62.1292 11.3267V14.2201H61.2448V11.5546C61.2448 10.8411 60.9367 10.4844 60.2809 10.4844C59.625 10.4844 59.2077 10.9204 59.2077 11.6141V14.2201H58.3232V9.76105V9.75115Z"
        fill="currentColor"
      />
      <path
        d="M63.5303 8.01758H64.4147V14.2207H63.5303V8.01758Z"
        fill="currentColor"
      />
      <path
        d="M65.6475 11.9808C65.6475 10.544 66.4524 9.66211 67.7542 9.66211C69.0559 9.66211 69.8609 10.5341 69.8609 11.9808C69.8609 13.4276 69.0559 14.2996 67.7542 14.2996C66.4524 14.2996 65.6475 13.4276 65.6475 11.9808ZM68.9566 11.9808C68.9566 11.0097 68.5193 10.4449 67.7542 10.4449C66.989 10.4449 66.5518 11.0097 66.5518 11.9808C66.5518 12.9519 66.9791 13.5167 67.7542 13.5167C68.5293 13.5167 68.9566 12.9519 68.9566 11.9808Z"
        fill="currentColor"
      />
      <path
        d="M70.7949 12.9518C70.7949 12.1491 71.3912 11.6834 72.4544 11.624L73.6668 11.5546V11.1681C73.6668 10.6925 73.3587 10.4349 72.7526 10.4349C72.2557 10.4349 71.9178 10.6132 71.8185 10.9303H70.9639C71.0533 10.1673 71.7787 9.67188 72.7923 9.67188C73.9152 9.67188 74.5512 10.2268 74.5512 11.1681V14.2201H73.6966V13.5959H73.627C73.3587 14.0418 72.8718 14.2994 72.2855 14.2994C71.421 14.2994 70.7949 13.7841 70.7949 12.9617V12.9518ZM73.6767 12.5752V12.1987L72.5836 12.2681C71.9675 12.3077 71.6893 12.5158 71.6893 12.9121C71.6893 13.3085 72.0371 13.5463 72.5141 13.5463C73.1799 13.5463 73.6767 13.1202 73.6767 12.5752Z"
        fill="currentColor"
      />
      <path
        d="M75.7139 11.9812C75.7139 10.5741 76.4393 9.68231 77.5721 9.68231C78.1882 9.68231 78.705 9.96967 78.9435 10.4651H79.013V8.01758H79.8974V14.2207H79.0528V13.5171H78.9832C78.7149 14.0027 78.1982 14.2999 77.5821 14.2999C76.4393 14.2999 75.7238 13.4081 75.7238 11.9911L75.7139 11.9812ZM76.6281 11.9812C76.6281 12.9325 77.0753 13.4973 77.8206 13.4973C78.5659 13.4973 79.023 12.9226 79.023 11.9812C79.023 11.0399 78.5559 10.4651 77.8206 10.4651C77.0852 10.4651 76.6281 11.0399 76.6281 11.9812Z"
        fill="currentColor"
      />
      <path
        d="M83.5439 11.9808C83.5439 10.544 84.3489 9.66211 85.6506 9.66211C86.9524 9.66211 87.7573 10.5341 87.7573 11.9808C87.7573 13.4276 86.9524 14.2996 85.6506 14.2996C84.3489 14.2996 83.5439 13.4276 83.5439 11.9808ZM86.853 11.9808C86.853 11.0097 86.4158 10.4449 85.6506 10.4449C84.8855 10.4449 84.4482 11.0097 84.4482 11.9808C84.4482 12.9519 84.8755 13.5167 85.6506 13.5167C86.4257 13.5167 86.853 12.9519 86.853 11.9808Z"
        fill="currentColor"
      />
      <path
        d="M88.9502 9.75115H89.8048V10.4646H89.8744C90.093 9.96914 90.5402 9.67188 91.206 9.67188C92.1997 9.67188 92.7562 10.2664 92.7562 11.3267V14.2201H91.8717V11.5546C91.8717 10.8411 91.5637 10.4844 90.9078 10.4844C90.252 10.4844 89.8346 10.9204 89.8346 11.6141V14.2201H88.9502V9.76105V9.75115Z"
        fill="currentColor"
      />
      <path
        d="M97.7451 8.64258V9.77221H98.7189V10.5154H97.7451V12.8143C97.7451 13.28 97.9339 13.4881 98.381 13.4881C98.5202 13.4881 98.5897 13.4881 98.7189 13.4683V14.2016C98.5798 14.2214 98.4109 14.2511 98.2419 14.2511C97.2581 14.2511 96.8706 13.9043 96.8706 13.0422V10.5253H96.165V9.78212H96.8706V8.65249H97.755L97.7451 8.64258Z"
        fill="currentColor"
      />
      <path
        d="M99.9111 8.01758H100.786V10.475H100.855C101.074 9.97958 101.541 9.6724 102.217 9.6724C103.171 9.6724 103.757 10.2768 103.757 11.3371V14.2207H102.872V11.5551C102.872 10.8417 102.545 10.4849 101.918 10.4849C101.193 10.4849 100.796 10.9408 100.796 11.6146V14.2207H99.9111V8.01758Z"
        fill="currentColor"
      />
      <path
        d="M108.914 13.0112C108.715 13.8139 108 14.2994 106.976 14.2994C105.694 14.2994 104.909 13.4274 104.909 12.0005C104.909 10.5736 105.714 9.67188 106.976 9.67188C108.238 9.67188 108.974 10.5241 108.974 11.9212V12.2284H105.813V12.278C105.843 13.0608 106.3 13.5562 107.006 13.5562C107.543 13.5562 107.91 13.368 108.069 13.0112H108.924H108.914ZM105.813 11.5744H108.069C108.049 10.8709 107.622 10.4151 106.966 10.4151C106.31 10.4151 105.853 10.8709 105.804 11.5744H105.813Z"
        fill="currentColor"
      />
      <path
        d="M120.891 0.995483H7.9273C3.82282 0.995483 0.495483 4.32282 0.495483 8.4273V32.2092C0.495483 36.3136 3.82282 39.641 7.9273 39.641H120.891C124.995 39.641 128.322 36.3136 128.322 32.2092V8.4273C128.322 4.32282 124.995 0.995483 120.891 0.995483Z"
        stroke="currentColor"
        strokeWidth="0.990909"
      />
    </g>
    <defs>
      <clipPath id="clip0_3104_5011">
        <rect
          width="129"
          height="40"
          fill="currentColor"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
)
