import { defaultIconProps } from 'components/common/icons'
import type { DefaultIconProps } from 'components/common/icons'

export const KidsModeOn = ({
  className = defaultIconProps.className,
  height = 25,
}: DefaultIconProps & { height?: number }) => {
  const width = (76 / 25) * height

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 42 14"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path d="M6.50549 11.5859H9.98987L6.45081 5.28125L10.0524 0.164062H6.67737L4.27893 3.66406C4.11747 3.88802 3.95081 4.125 3.77893 4.375C3.61226 4.625 3.46383 4.875 3.33362 5.125V0.164062H0.232056V11.5859H3.33362V7.76562L4.24768 7.21875L6.50549 11.5859Z" />
      <path d="M10.3521 0.164062V11.5859H13.4536V0.164062H10.3521Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.0033 8.94531C24.4981 8.0651 24.7455 6.96094 24.7455 5.63281C24.7455 4.45573 24.5215 3.46094 24.0736 2.64844C23.6309 1.83594 22.9825 1.21875 22.1283 0.796875C21.2741 0.375 20.2325 0.164062 19.0033 0.164062H15.0502V11.5859H18.7455C19.959 11.5859 21.0137 11.3672 21.9096 10.9297C22.8106 10.487 23.5085 9.82552 24.0033 8.94531ZM21.2611 4.02344C21.4486 4.47656 21.5424 5.04948 21.5424 5.74219C21.5424 6.86198 21.3236 7.69531 20.8861 8.24219C20.4486 8.78385 19.7611 9.05469 18.8236 9.05469H18.1361V2.65625H19.0346C19.5971 2.65625 20.0632 2.77083 20.433 3C20.8028 3.22396 21.0788 3.5651 21.2611 4.02344Z"
      />
      <path d="M32.0215 5.46791C31.9268 5.75712 31.8756 6.06602 31.8756 6.38688V10.4864C31.8756 10.6462 31.8883 10.8031 31.9128 10.956C31.772 11.0524 31.6208 11.1426 31.4592 11.2266C30.803 11.5703 29.954 11.7422 28.9124 11.7422C28.3915 11.7422 27.9332 11.7188 27.5374 11.6719C27.1467 11.625 26.7822 11.5495 26.4436 11.4453C26.1051 11.3359 25.7535 11.1953 25.3889 11.0234V8.27344C26.0087 8.58594 26.6337 8.82552 27.2639 8.99219C27.8941 9.15365 28.4645 9.23438 28.9749 9.23438C29.2822 9.23438 29.5322 9.19792 29.7249 9.125C29.9228 9.05208 30.0686 8.95312 30.1624 8.82812C30.2561 8.69792 30.303 8.54948 30.303 8.38281C30.303 8.1849 30.2327 8.01823 30.0921 7.88281C29.9566 7.74219 29.7379 7.59635 29.4358 7.44531C29.1337 7.29427 28.7353 7.10677 28.2405 6.88281C27.8186 6.6849 27.4358 6.48177 27.0921 6.27344C26.7535 6.0651 26.4618 5.82812 26.2171 5.5625C25.9775 5.29167 25.7926 4.97656 25.6624 4.61719C25.5322 4.25781 25.4671 3.83333 25.4671 3.34375C25.4671 2.60938 25.6467 1.99479 26.0061 1.5C26.3707 1 26.8759 0.625 27.5217 0.375C28.1728 0.125 28.9228 0 29.7717 0C30.5165 0 31.1858 0.0833333 31.7796 0.25C32.3785 0.416667 32.915 0.606771 33.3889 0.820312L32.4436 3.20312C31.954 2.97917 31.4723 2.80208 30.9983 2.67188C30.5243 2.53646 30.0842 2.46875 29.678 2.46875C29.4124 2.46875 29.1936 2.5026 29.0217 2.57031C28.8551 2.63281 28.7301 2.71875 28.6467 2.82812C28.5686 2.9375 28.5296 3.0625 28.5296 3.20312C28.5296 3.38021 28.5973 3.53906 28.7327 3.67969C28.8733 3.82031 29.1051 3.97396 29.428 4.14062C29.7561 4.30208 30.2014 4.51302 30.7639 4.77344C31.2453 4.98939 31.6645 5.22088 32.0215 5.46791Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.9254 4.43665H34.8258C33.7488 4.43665 32.8756 5.3098 32.8756 6.38688V10.4864C32.8756 11.5635 33.7488 12.4366 34.8258 12.4366H38.9254C40.0025 12.4366 40.8756 11.5635 40.8756 10.4864V6.38688C40.8756 5.3098 40.0025 4.43665 38.9254 4.43665ZM34.8258 3.43665C33.1965 3.43665 31.8756 4.75751 31.8756 6.38688V10.4864C31.8756 12.1158 33.1965 13.4366 34.8258 13.4366H38.9254C40.5547 13.4366 41.8756 12.1158 41.8756 10.4864V6.38688C41.8756 4.75751 40.5547 3.43665 38.9254 3.43665H34.8258Z"
      />
      <path d="M36.5468 9.99466C37.4256 9.05436 38.274 8.41648 39.3397 7.51315C39.6587 7.24276 39.7079 6.76882 39.4577 6.43304C39.1918 6.07617 38.6864 6.00484 38.3444 6.28855C37.3682 7.09824 36.6087 7.87786 35.8883 8.71113C35.8505 8.75485 35.8316 8.77672 35.812 8.78903C35.7622 8.82024 35.7001 8.82056 35.65 8.78988C35.6303 8.77777 35.6113 8.75633 35.5734 8.71346L35.1896 8.27882C34.8393 7.88221 34.211 7.92613 33.9186 8.36763C33.7139 8.67659 33.7442 9.08603 33.9922 9.36105L34.5941 10.0288C35.0444 10.5283 35.2695 10.778 35.5398 10.7804C35.8101 10.7829 36.0556 10.5202 36.5468 9.99466Z" />
    </svg>
  )
}

export default KidsModeOn
