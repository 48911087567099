import { defaultIconProps } from 'components/common/icons'
import type { DefaultIconProps } from 'components/common/icons'

export const Share = ({
  size = defaultIconProps.size,
  className = defaultIconProps.className,
}: DefaultIconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 21"
      height={size}
      width={size}
      fill="currentColor">
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M2.66222 20.0592C2.17289 20.0592 1.75398 19.8832 1.40551 19.5312C1.05705 19.1793 0.882812 18.7561 0.882812 18.2618V8.37622C0.882812 7.88194 1.05705 7.4588 1.40551 7.10682C1.75398 6.75483 2.17289 6.57883 2.66222 6.57883H4.44164C4.69372 6.57883 4.90502 6.66496 5.07555 6.83721C5.24608 7.00946 5.33134 7.2229 5.33134 7.47753C5.33134 7.73216 5.24608 7.9456 5.07555 8.11784C4.90502 8.29009 4.69372 8.37622 4.44164 8.37622H2.66222V18.2618H13.3387V8.37622H11.5593C11.3072 8.37622 11.0959 8.29009 10.9254 8.11784C10.7548 7.9456 10.6696 7.73216 10.6696 7.47753C10.6696 7.2229 10.7548 7.00946 10.9254 6.83721C11.0959 6.66496 11.3072 6.57883 11.5593 6.57883H13.3387C13.828 6.57883 14.2469 6.75483 14.5954 7.10682C14.9439 7.4588 15.1181 7.88194 15.1181 8.37622V18.2618C15.1181 18.7561 14.9439 19.1793 14.5954 19.5312C14.2469 19.8832 13.828 20.0592 13.3387 20.0592H2.66222ZM7.11075 3.72548L6.31002 4.53431C6.13208 4.71405 5.92448 4.80017 5.68722 4.79268C5.44997 4.78519 5.24237 4.69158 5.06443 4.51184C4.90132 4.3321 4.81605 4.12241 4.80864 3.88275C4.80123 3.6431 4.88649 3.43341 5.06443 3.25367L7.37767 0.917069C7.55561 0.73733 7.7632 0.647461 8.00046 0.647461C8.23771 0.647461 8.44531 0.73733 8.62325 0.917069L10.9365 3.25367C11.0996 3.41843 11.1812 3.62438 11.1812 3.87152C11.1812 4.11866 11.0996 4.3321 10.9365 4.51184C10.7585 4.69158 10.5472 4.78145 10.3026 4.78145C10.0579 4.78145 9.8466 4.69158 9.66866 4.51184L8.89017 3.72548V12.8697C8.89017 13.1243 8.8049 13.3378 8.63438 13.51C8.46385 13.6823 8.25254 13.7684 8.00046 13.7684C7.74838 13.7684 7.53707 13.6823 7.36654 13.51C7.19602 13.3378 7.11075 13.1243 7.11075 12.8697V3.72548Z"
        fillOpacity="0.75"
      />
    </svg>
  )
}
