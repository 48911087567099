export const AmazonAppStoreBadge = () => (
  <svg
    width="129"
    height="41"
    viewBox="0 0 129 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3104_4982)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.7567 28.9368C38.1446 30.8656 34.3611 31.8848 31.1086 31.8848C26.5415 31.8848 22.4294 30.2002 19.3201 27.3871C19.0757 27.1681 19.2948 26.8648 19.5898 27.0333C22.9434 28.9874 27.0976 30.1581 31.3866 30.1581C34.2769 30.1581 37.462 29.5601 40.386 28.322C40.8241 28.1367 41.1949 28.6084 40.7652 28.9284L40.7567 28.9368Z"
        fill="#F0F1F5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.8348 27.7003C41.4978 27.2708 39.6271 27.4982 38.7845 27.5993C38.5317 27.633 38.4896 27.4055 38.7171 27.2455C40.2086 26.1927 42.6606 26.4959 42.9387 26.8496C43.2252 27.2034 42.8629 29.6544 41.4641 30.8252C41.245 31.0021 41.0428 30.9094 41.1355 30.6736C41.4472 29.8903 42.155 28.1299 41.818 27.7003H41.8348Z"
        fill="#F0F1F5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.8517 19.833V18.8138C38.8517 18.6622 38.9696 18.5527 39.1129 18.5527H43.68C43.8232 18.5527 43.9412 18.6622 43.9412 18.8054V19.6814C43.9412 19.8246 43.8148 20.0183 43.5957 20.3215L41.2279 23.699C42.1042 23.6822 43.0396 23.8085 43.8316 24.2549C44.0086 24.356 44.0592 24.5076 44.076 24.6508V25.7373C44.076 25.8889 43.9159 26.0574 43.739 25.9732C42.3318 25.2404 40.4695 25.1562 38.9107 25.9816C38.7506 26.0658 38.582 25.8974 38.582 25.7457V24.7097C38.582 24.5413 38.582 24.2633 38.7506 24.0107L41.4891 20.0772H39.1045C38.9612 20.0772 38.8432 19.9762 38.8432 19.8246L38.8517 19.833Z"
        fill="#F0F1F5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.1851 26.2H20.7948C20.66 26.1915 20.5588 26.0905 20.542 25.9641V18.8301C20.542 18.6869 20.66 18.5774 20.8116 18.5774H22.1093C22.2441 18.5774 22.3537 18.6869 22.3621 18.8132V19.7481H22.3874C22.7244 18.8469 23.3564 18.4258 24.2159 18.4258C25.0754 18.4258 25.6315 18.8469 26.0191 19.7481C26.3562 18.8469 27.123 18.4258 27.9403 18.4258C28.5217 18.4258 29.1621 18.67 29.5498 19.2091C29.9879 19.8071 29.9037 20.6831 29.9037 21.4411V25.9389C29.9037 26.082 29.7857 26.1915 29.634 26.1915H28.2437C28.1004 26.1831 27.9909 26.0736 27.9909 25.9389V22.157C27.9909 21.8538 28.0162 21.1042 27.9487 20.8178C27.8476 20.3377 27.5359 20.203 27.1314 20.203C26.7943 20.203 26.4404 20.4304 26.2972 20.7841C26.1539 21.1463 26.1708 21.7443 26.1708 22.1486V25.9304C26.1708 26.0736 26.0528 26.1831 25.9011 26.1831H24.5108C24.3676 26.1747 24.258 26.0652 24.258 25.9304V22.1486C24.258 21.3569 24.3844 20.1861 23.3985 20.1861C22.4126 20.1861 22.4379 21.3232 22.4379 22.1486V25.9304C22.4379 26.0736 22.32 26.1831 22.1683 26.1831L22.1851 26.2Z"
        fill="#F0F1F5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.8762 18.4264C49.9406 18.4264 51.0529 20.1952 51.0529 22.444C51.0529 24.6929 49.8227 26.3438 47.8762 26.3438C45.9297 26.3438 44.75 24.575 44.75 22.3682C44.75 20.1615 45.8623 18.418 47.8762 18.418V18.4264ZM47.8846 19.8835C46.8566 19.8835 46.7976 21.2817 46.7976 22.1492C46.7976 23.0168 46.7807 24.8782 47.8762 24.8782C48.9716 24.8782 49.0053 23.379 49.0053 22.4609C49.0053 21.8629 48.98 21.1385 48.7946 20.5658C48.643 20.0688 48.3312 19.8751 47.8846 19.8751V19.8835Z"
        fill="#F0F1F5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.7333 26.2013H52.3514C52.2082 26.1929 52.0986 26.0834 52.0986 25.9486V18.8146C52.1071 18.6798 52.225 18.5787 52.3599 18.5787H53.6491C53.767 18.5787 53.8682 18.6714 53.8934 18.7809V19.8758H53.9187C54.3063 18.8988 54.854 18.4355 55.8147 18.4355C56.4382 18.4355 57.0449 18.663 57.4325 19.2778C57.7948 19.8506 57.7948 20.8108 57.7948 21.5014V25.9907C57.778 26.1171 57.6684 26.2181 57.5252 26.2181H56.1349C56.0085 26.2181 55.8989 26.1171 55.8905 25.9907V22.1163C55.8905 21.333 55.9832 20.1959 55.0226 20.1959C54.6855 20.1959 54.3737 20.4233 54.2221 20.7686C54.0283 21.2066 54.003 21.6362 54.003 22.1163V25.957C54.003 26.1002 53.8766 26.2097 53.7333 26.2097V26.2013Z"
        fill="#F0F1F5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.212 22.789C35.212 23.3281 35.2289 23.7829 34.9508 24.263C34.7317 24.6504 34.3778 24.8947 33.9902 24.8947C33.4593 24.8947 33.1476 24.4904 33.1476 23.884C33.1476 22.7048 34.2093 22.4858 35.212 22.4858V22.789ZM36.6108 26.1749C36.5181 26.2592 36.3833 26.2592 36.2822 26.2086C35.8187 25.8212 35.7345 25.6443 35.4817 25.2821C34.7149 26.057 34.1756 26.2929 33.1897 26.2929C32.0184 26.2929 31.1084 25.5685 31.1084 24.1282C31.1084 22.9996 31.7235 22.2331 32.5914 21.8541C33.3414 21.5256 34.4031 21.4666 35.2036 21.374V21.1971C35.2036 20.8686 35.2289 20.4727 35.0351 20.1864C34.8665 19.9337 34.5379 19.8242 34.2514 19.8242C33.7206 19.8242 33.2487 20.0937 33.1307 20.658C33.1054 20.7844 33.0128 20.9107 32.8864 20.9107L31.5381 20.7675C31.4202 20.7423 31.3022 20.6496 31.3275 20.4727C31.6393 18.8387 33.1139 18.3418 34.4368 18.3418C35.1109 18.3418 35.9957 18.5187 36.5265 19.0325C37.2006 19.6642 37.1417 20.5064 37.1417 21.4245V23.5892C37.1417 24.2377 37.4113 24.5241 37.6641 24.8778C37.7568 25.0042 37.7736 25.1558 37.6641 25.2484C37.3776 25.4843 36.872 25.9223 36.6024 26.1749H36.6108Z"
        fill="#F0F1F5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9855 22.789C16.9855 23.3281 17.0023 23.7829 16.7242 24.263C16.5052 24.6504 16.1513 24.8947 15.7636 24.8947C15.2328 24.8947 14.921 24.4904 14.921 23.884C14.921 22.7048 15.9827 22.4858 16.9855 22.4858V22.789ZM18.3842 26.1749C18.2915 26.2592 18.1567 26.2592 18.0556 26.2086C17.5922 25.8212 17.5079 25.6443 17.2551 25.2821C16.4883 26.057 15.949 26.2929 14.9631 26.2929C13.7919 26.2929 12.8818 25.5685 12.8818 24.1282C12.8818 22.9996 13.497 22.2331 14.3649 21.8541C15.1148 21.5256 16.1765 21.4666 16.977 21.374V21.1971C16.977 20.8686 17.0023 20.4727 16.8085 20.1864C16.64 19.9337 16.3114 19.8242 16.0249 19.8242C15.494 19.8242 15.0221 20.0937 14.9042 20.658C14.8789 20.7844 14.7862 20.9107 14.6598 20.9107L13.3116 20.7675C13.1936 20.7423 13.0672 20.6496 13.1009 20.4727C13.4127 18.8387 14.8873 18.3418 16.2102 18.3418C16.8843 18.3418 17.7691 18.5187 18.3 19.0325C18.9741 19.6642 18.9151 20.5064 18.9151 21.4245V23.5892C18.9151 24.2377 19.1847 24.5241 19.4375 24.8778C19.5302 25.0042 19.5471 25.1558 19.4375 25.2484C19.151 25.4843 18.6455 25.9223 18.3674 26.1749H18.3842Z"
        fill="#F0F1F5"
      />
      <path
        d="M63.4398 26.2753C62.7657 26.2753 62.2095 26.0731 61.7882 25.6688C61.3669 25.2645 61.1562 24.7339 61.1562 24.0854C61.1562 23.3694 61.4175 22.7967 61.9315 22.3756C62.4455 21.946 63.1449 21.7354 64.0128 21.7354C64.5268 21.7354 65.0829 21.8112 65.698 21.9544V21.0953C65.698 20.6068 65.5885 20.2614 65.3779 20.0677C65.1672 19.874 64.7964 19.7814 64.2571 19.7814C63.9369 19.7814 63.583 19.815 63.1954 19.874C62.8078 19.9414 62.4371 20.0256 62.0916 20.1435C61.9989 20.1688 61.9146 20.1856 61.8472 20.1856C61.7208 20.1856 61.6618 20.093 61.6618 19.9077V19.3686C61.6618 19.2339 61.6787 19.1328 61.7208 19.0823C61.7629 19.0233 61.8641 18.9643 62.0242 18.897C62.7825 18.6106 63.6167 18.459 64.5352 18.459C65.4537 18.459 66.1784 18.6527 66.6334 19.0402C67.0884 19.4276 67.3075 20.034 67.3075 20.851V25.7362C67.3075 25.9468 67.1979 26.0563 66.9873 26.0563H66.2205C66.0098 26.0563 65.8834 25.9636 65.8582 25.7699L65.7823 25.3488C65.4453 25.6351 65.0661 25.8626 64.6616 26.031C64.2487 26.1995 63.8443 26.2753 63.4314 26.2753H63.4398ZM63.9201 25.0624C64.1982 25.0624 64.4931 25.0034 64.8049 24.8771C65.1166 24.7592 65.4116 24.5823 65.7065 24.3633V22.9736C65.1925 22.8641 64.7374 22.8135 64.3414 22.8135C63.3387 22.8135 62.8331 23.201 62.8331 23.9927C62.8331 24.3296 62.9258 24.5907 63.1196 24.7845C63.3134 24.9698 63.5746 25.0708 63.9201 25.0708V25.0624Z"
        fill="#F0F1F5"
      />
      <path
        d="M69.1786 29.047C68.9679 29.047 68.8584 28.9375 68.8584 28.7269V18.9902C68.8584 18.7797 68.9679 18.6702 69.1786 18.6702H69.9791C70.1898 18.6702 70.3162 18.7628 70.3583 18.9565L70.4426 19.4029C70.7796 19.0913 71.1588 18.8555 71.5633 18.6954C71.9761 18.527 72.4059 18.4512 72.8441 18.4512C73.7625 18.4512 74.4956 18.7965 75.0433 19.4787C75.5911 20.1694 75.8607 21.0875 75.8607 22.2498C75.8607 23.05 75.7259 23.7575 75.4647 24.3555C75.195 24.9535 74.8243 25.4168 74.3524 25.7368C73.8805 26.0569 73.3328 26.2169 72.7261 26.2169C72.3216 26.2169 71.9256 26.1495 71.5464 26.0064C71.1672 25.8632 70.8386 25.6694 70.5605 25.4168V28.7269C70.5605 28.9375 70.451 29.047 70.2403 29.047H69.1702H69.1786ZM72.2963 24.8693C72.9115 24.8693 73.3749 24.6587 73.6783 24.2376C73.9816 23.8165 74.1249 23.1763 74.1249 22.3172C74.1249 21.4581 73.9816 20.8348 73.6783 20.4221C73.3749 20.0094 72.9283 19.8072 72.3048 19.8072C71.6812 19.8072 71.0914 19.9925 70.5605 20.3547V24.305C71.0998 24.684 71.6812 24.8693 72.2879 24.8693H72.2963Z"
        fill="#F0F1F5"
      />
      <path
        d="M77.4188 29.047C77.2082 29.047 77.0986 28.9375 77.0986 28.7269V18.9902C77.0986 18.7797 77.2082 18.6702 77.4188 18.6702H78.2193C78.43 18.6702 78.5564 18.7628 78.5985 18.9565L78.6828 19.4029C79.0198 19.0913 79.399 18.8555 79.8035 18.6954C80.2164 18.527 80.6461 18.4512 81.0843 18.4512C82.0028 18.4512 82.7359 18.7965 83.2836 19.4787C83.8313 20.1694 84.1009 21.0875 84.1009 22.2498C84.1009 23.05 83.9661 23.7575 83.7049 24.3555C83.4352 24.9535 83.0645 25.4168 82.5926 25.7368C82.1207 26.0569 81.573 26.2169 80.9663 26.2169C80.5619 26.2169 80.1658 26.1495 79.7866 26.0064C79.4075 25.8632 79.0788 25.6694 78.8008 25.4168V28.7269C78.8008 28.9375 78.6912 29.047 78.4806 29.047H77.4104H77.4188ZM80.545 24.8693C81.1601 24.8693 81.6236 24.6587 81.9269 24.2376C82.2303 23.8165 82.3735 23.1763 82.3735 22.3172C82.3735 21.4581 82.2303 20.8348 81.9269 20.4221C81.6236 20.0094 81.177 19.8072 80.5534 19.8072C79.9299 19.8072 79.34 19.9925 78.8092 20.3547V24.305C79.3485 24.684 79.9299 24.8693 80.5366 24.8693H80.545Z"
        fill="#F0F1F5"
      />
      <path
        d="M87.3279 26.2759C86.5611 26.2759 85.8533 26.1495 85.2129 25.8969C85.0612 25.8379 84.9601 25.7789 84.918 25.7116C84.8759 25.6526 84.8506 25.5431 84.8506 25.3999V24.8777C84.8506 24.684 84.918 24.5913 85.0528 24.5913C85.1202 24.5913 85.2551 24.625 85.4573 24.6756C86.1482 24.8693 86.7634 24.9619 87.3195 24.9619C87.7998 24.9619 88.1537 24.8861 88.3812 24.7345C88.6003 24.5829 88.7183 24.3555 88.7183 24.0439C88.7183 23.8333 88.6509 23.6564 88.516 23.5301C88.3812 23.3953 88.1284 23.269 87.7577 23.1342L86.5527 22.671C85.4657 22.2582 84.9264 21.576 84.9264 20.6411C84.9264 19.9673 85.1792 19.4282 85.6848 19.0408C86.1904 18.6533 86.8645 18.4512 87.6987 18.4512C88.297 18.4512 88.9037 18.5522 89.5104 18.7544C89.6536 18.8049 89.7547 18.8555 89.7968 18.9228C89.8474 18.9902 89.8727 19.0913 89.8727 19.2513V19.7567C89.8727 19.8662 89.8558 19.9336 89.8221 19.9841C89.7884 20.0346 89.7294 20.0515 89.6452 20.0515C89.5946 20.0515 89.4767 20.0262 89.2828 19.9757C88.6761 19.8325 88.179 19.7567 87.7998 19.7567C87.3532 19.7567 87.0246 19.8241 86.8223 19.9504C86.6117 20.0768 86.5106 20.2873 86.5106 20.5653C86.5106 20.7758 86.5864 20.9527 86.7381 21.0791C86.8898 21.2054 87.1594 21.3486 87.547 21.5086L88.6677 21.9466C89.2491 22.1656 89.6705 22.4351 89.9232 22.7468C90.176 23.0584 90.3109 23.4458 90.3109 23.9259C90.3109 24.6503 90.0496 25.223 89.5272 25.6442C89.0048 26.0653 88.2801 26.2759 87.3532 26.2759H87.3279Z"
        fill="#F0F1F5"
      />
      <path
        d="M94.3475 26.1583C93.5807 26.1583 93.0162 25.9814 92.6538 25.6193C92.2831 25.2655 92.0977 24.718 92.0977 23.9768V19.976H91.1708C90.9601 19.976 90.8506 19.8665 90.8506 19.656V19.2348C90.8506 19.1085 90.8759 19.0158 90.9264 18.9653C90.977 18.9148 91.0528 18.8726 91.1624 18.8558L92.1651 18.7126L92.3842 16.9607C92.3926 16.8428 92.4263 16.7585 92.4853 16.7164C92.5443 16.6659 92.6285 16.6406 92.7465 16.6406H93.5049C93.7155 16.6406 93.8251 16.7501 93.8251 16.9607V18.6705H95.6115C95.8221 18.6705 95.9317 18.78 95.9317 18.9906V19.656C95.9317 19.8665 95.8221 19.976 95.6115 19.976H93.8251V23.8926C93.8251 24.2211 93.9009 24.4569 94.061 24.5917C94.2211 24.7349 94.4908 24.8022 94.87 24.8022C95.089 24.8022 95.2744 24.7854 95.4092 24.7601C95.5441 24.7349 95.6536 24.718 95.7463 24.718C95.9148 24.718 95.9907 24.8107 95.9907 24.996V25.5182C95.9907 25.6698 95.9654 25.7793 95.9148 25.8467C95.8643 25.914 95.7631 25.9646 95.6199 25.9983C95.2154 26.1078 94.7941 26.1583 94.3728 26.1583H94.3475Z"
        fill="#F0F1F5"
      />
      <path
        d="M100.001 26.2753C98.8808 26.2753 98.0044 25.9299 97.3725 25.2393C96.7405 24.5486 96.4287 23.5884 96.4287 22.3587C96.4287 21.129 96.7405 20.1772 97.3725 19.4866C98.0044 18.8043 98.8808 18.459 100.001 18.459C101.122 18.459 101.999 18.8043 102.631 19.4866C103.262 20.1772 103.574 21.129 103.574 22.3587C103.574 23.5884 103.262 24.5486 102.631 25.2393C101.999 25.9299 101.122 26.2753 100.001 26.2753ZM100.001 24.9276C101.223 24.9276 101.83 24.0685 101.83 22.3587C101.83 20.6489 101.223 19.8066 100.001 19.8066C98.7797 19.8066 98.173 20.6573 98.173 22.3587C98.173 24.0601 98.7797 24.9276 100.001 24.9276Z"
        fill="#F0F1F5"
      />
      <path
        d="M105.108 26.057C104.898 26.057 104.788 25.9475 104.788 25.737V18.9904C104.788 18.7798 104.898 18.6703 105.108 18.6703H105.875C106.01 18.6703 106.103 18.6871 106.162 18.7292C106.212 18.7714 106.254 18.8387 106.271 18.9482L106.448 19.7737C106.836 19.3525 107.198 19.0493 107.535 18.8724C107.872 18.6956 108.243 18.6113 108.639 18.6113H108.698C108.824 18.6113 108.925 18.6198 109.01 18.645C109.094 18.6703 109.153 18.7124 109.187 18.7714C109.22 18.8303 109.237 18.9398 109.237 19.0914V19.8747C109.237 20.0769 109.153 20.178 108.976 20.178C108.917 20.178 108.841 20.178 108.74 20.1611C108.639 20.1527 108.529 20.1443 108.411 20.1443C107.695 20.1443 107.063 20.3296 106.507 20.6917V25.7285C106.507 25.9391 106.398 26.0486 106.187 26.0486H105.117L105.108 26.057Z"
        fill="#F0F1F5"
      />
      <path
        d="M113.332 26.2753C112.11 26.2753 111.192 25.9468 110.56 25.2898C109.928 24.6328 109.616 23.6727 109.616 22.4177C109.616 21.1627 109.928 20.1941 110.535 19.5034C111.15 18.8043 112.009 18.459 113.113 18.459C114.065 18.459 114.79 18.7201 115.296 19.2339C115.801 19.7477 116.054 20.4804 116.054 21.4322C116.054 21.7607 116.029 22.106 115.978 22.4598C115.961 22.5861 115.919 22.6703 115.869 22.7125C115.818 22.7546 115.742 22.7798 115.658 22.7798H111.184C111.209 23.5126 111.419 24.0517 111.799 24.397C112.178 24.7423 112.768 24.9108 113.56 24.9108C114.141 24.9108 114.748 24.8181 115.388 24.6328C115.464 24.616 115.523 24.5992 115.565 24.5907C115.607 24.5823 115.641 24.5739 115.666 24.5739C115.81 24.5739 115.885 24.6665 115.885 24.8603V25.3825C115.885 25.5257 115.86 25.6267 115.818 25.6941C115.776 25.7531 115.675 25.8204 115.523 25.871C114.874 26.1321 114.141 26.2669 113.315 26.2669L113.332 26.2753ZM114.554 21.6344V21.5164C114.554 20.9268 114.428 20.4804 114.166 20.1688C113.914 19.8572 113.534 19.7055 113.046 19.7055C112.523 19.7055 112.102 19.8824 111.765 20.2278C111.428 20.5731 111.251 21.0448 111.2 21.6344H114.554Z"
        fill="#F0F1F5"
      />
      <path
        d="M46.7725 14.1392C46.4101 14.1392 46.1236 14.0382 45.913 13.836C45.6939 13.6339 45.5928 13.3559 45.5928 13.0106C45.5928 12.64 45.7276 12.3452 45.9888 12.1262C46.25 11.9072 46.6039 11.7977 47.0505 11.7977C47.337 11.7977 47.6488 11.8398 48.0027 11.924V11.4271C48.0027 11.1576 47.9437 10.9639 47.8173 10.8544C47.6909 10.7449 47.4803 10.6859 47.1854 10.6859C46.8399 10.6859 46.4944 10.7364 46.1658 10.8375C46.0478 10.8712 45.972 10.888 45.9467 10.888C45.8877 10.888 45.854 10.8459 45.854 10.7533V10.5511C45.854 10.4838 45.8624 10.4332 45.8877 10.408C45.9045 10.3743 45.9467 10.349 46.0057 10.3237C46.1658 10.2563 46.3596 10.1974 46.6039 10.1553C46.8483 10.1132 47.0842 10.0879 47.3202 10.0879C47.8005 10.0879 48.1544 10.189 48.3819 10.3911C48.6094 10.5933 48.7189 10.8965 48.7189 11.3008V13.895C48.7189 13.996 48.6684 14.0466 48.5673 14.0466H48.2386C48.146 14.0466 48.087 13.996 48.0701 13.9034L48.0364 13.6507C47.851 13.8107 47.6572 13.9287 47.4297 14.0129C47.2106 14.0971 46.9915 14.1392 46.7725 14.1392ZM46.9578 13.5665C47.1264 13.5665 47.2949 13.5328 47.4803 13.4654C47.6657 13.398 47.8342 13.3054 48.0027 13.179V12.4041C47.7331 12.3368 47.4634 12.3031 47.2022 12.3031C46.6292 12.3031 46.3427 12.5221 46.3427 12.96C46.3427 13.1538 46.3933 13.297 46.5028 13.4064C46.6124 13.5075 46.764 13.5581 46.9578 13.5581V13.5665Z"
        fill="#F0F1F5"
      />
      <path
        d="M50.7916 14.0373C50.7242 14.0373 50.6652 14.0289 50.6399 14.0036C50.6062 13.9868 50.5725 13.9278 50.5388 13.8267L49.199 10.4576C49.199 10.4576 49.1737 10.3987 49.1653 10.365C49.1484 10.3313 49.1484 10.306 49.1484 10.2808C49.1484 10.2134 49.1821 10.1797 49.258 10.1797H49.7046C49.7804 10.1797 49.831 10.1881 49.8647 10.2134C49.8984 10.2302 49.9237 10.2892 49.9658 10.3903L51.0022 13.3466L52.0387 10.3903C52.0724 10.2976 52.1061 10.2386 52.1398 10.2134C52.1735 10.1965 52.2241 10.1797 52.2999 10.1797H52.7212C52.797 10.1797 52.8392 10.2134 52.8392 10.2808C52.8392 10.306 52.8392 10.3313 52.8223 10.365C52.8139 10.3987 52.797 10.4239 52.7886 10.4576L51.4573 13.8267C51.4236 13.9194 51.3898 13.9783 51.3561 14.0036C51.3224 14.0205 51.2719 14.0373 51.2045 14.0373H50.8169H50.7916Z"
        fill="#F0F1F5"
      />
      <path
        d="M54.3145 14.1392C53.9521 14.1392 53.6656 14.0382 53.455 13.836C53.2359 13.6339 53.1348 13.3559 53.1348 13.0106C53.1348 12.64 53.2696 12.3452 53.5308 12.1262C53.792 11.9072 54.1459 11.7977 54.5925 11.7977C54.879 11.7977 55.1908 11.8398 55.5447 11.924V11.4271C55.5447 11.1576 55.4857 10.9639 55.3593 10.8544C55.2329 10.7449 55.0223 10.6859 54.7273 10.6859C54.3819 10.6859 54.0364 10.7364 53.7078 10.8375C53.5898 10.8712 53.514 10.888 53.4887 10.888C53.4297 10.888 53.396 10.8459 53.396 10.7533V10.5511C53.396 10.4838 53.4044 10.4332 53.4297 10.408C53.4465 10.3743 53.4887 10.349 53.5477 10.3237C53.7078 10.2563 53.9016 10.1974 54.1459 10.1553C54.3903 10.1132 54.6262 10.0879 54.8622 10.0879C55.3425 10.0879 55.6964 10.189 55.9239 10.3911C56.1514 10.5933 56.2609 10.8965 56.2609 11.3008V13.895C56.2609 13.996 56.2104 14.0466 56.1093 14.0466H55.7806C55.688 14.0466 55.629 13.996 55.6121 13.9034L55.5784 13.6507C55.393 13.8107 55.1992 13.9287 54.9717 14.0129C54.7526 14.0971 54.5335 14.1392 54.3145 14.1392ZM54.4914 13.5665C54.6599 13.5665 54.8285 13.5328 55.0138 13.4654C55.1992 13.398 55.3677 13.3054 55.5363 13.179V12.4041C55.2666 12.3368 54.997 12.3031 54.7358 12.3031C54.1628 12.3031 53.8763 12.5221 53.8763 12.96C53.8763 13.1538 53.9268 13.297 54.0364 13.4064C54.1459 13.5075 54.2976 13.5581 54.4914 13.5581V13.5665Z"
        fill="#F0F1F5"
      />
      <path
        d="M57.6594 9.51512C57.5161 9.51512 57.3982 9.47301 57.3139 9.38878C57.2296 9.30455 57.1875 9.19506 57.1875 9.0603C57.1875 8.92553 57.2296 8.81604 57.3139 8.73181C57.3982 8.64758 57.5161 8.60547 57.6594 8.60547C57.8026 8.60547 57.9206 8.64758 58.0049 8.73181C58.0891 8.81604 58.1312 8.92553 58.1312 9.0603C58.1312 9.19506 58.0891 9.30455 58.0049 9.38878C57.9206 9.47301 57.8026 9.51512 57.6594 9.51512ZM57.4319 14.0381C57.3307 14.0381 57.2802 13.9876 57.2802 13.8865V10.349C57.2802 10.2479 57.3307 10.1974 57.4319 10.1974H57.8785C57.9796 10.1974 58.0301 10.2479 58.0301 10.349V13.8865C58.0301 13.9876 57.9796 14.0381 57.8785 14.0381H57.4319Z"
        fill="#F0F1F5"
      />
      <path
        d="M59.901 14.071C59.6313 14.071 59.4291 13.9952 59.2859 13.8435C59.1426 13.6919 59.0752 13.4645 59.0752 13.1782V8.57934C59.0752 8.47827 59.1258 8.42773 59.2269 8.42773H59.6735C59.7746 8.42773 59.8251 8.47827 59.8251 8.57934V13.136C59.8251 13.254 59.8504 13.3382 59.901 13.3887C59.9515 13.4393 60.0274 13.4645 60.1369 13.4645C60.1706 13.4645 60.1959 13.4645 60.2296 13.4645C60.2633 13.4645 60.2886 13.4645 60.3139 13.4645C60.3644 13.4645 60.3981 13.4814 60.415 13.4982C60.4318 13.5235 60.4403 13.5656 60.4403 13.6077V13.852C60.4403 13.9025 60.4318 13.9446 60.4066 13.9699C60.3813 13.9952 60.3476 14.0204 60.2886 14.0373C60.1622 14.071 60.0274 14.0794 59.8925 14.0794L59.901 14.071Z"
        fill="#F0F1F5"
      />
      <path
        d="M62.0576 14.1392C61.6953 14.1392 61.4088 14.0382 61.1981 13.836C60.979 13.6339 60.8779 13.3559 60.8779 13.0106C60.8779 12.64 61.0128 12.3452 61.274 12.1262C61.5352 11.9072 61.8891 11.7977 62.3357 11.7977C62.6222 11.7977 62.934 11.8398 63.2879 11.924V11.4271C63.2879 11.1576 63.2289 10.9639 63.1025 10.8544C62.9761 10.7449 62.7654 10.6859 62.4705 10.6859C62.125 10.6859 61.7795 10.7364 61.4509 10.8375C61.333 10.8712 61.2571 10.888 61.2318 10.888C61.1729 10.888 61.1392 10.8459 61.1392 10.7533V10.5511C61.1392 10.4838 61.1476 10.4332 61.1729 10.408C61.1897 10.3743 61.2318 10.349 61.2908 10.3237C61.4509 10.2563 61.6447 10.1974 61.8891 10.1553C62.1335 10.1132 62.3694 10.0879 62.6053 10.0879C63.0856 10.0879 63.4395 10.189 63.6671 10.3911C63.8946 10.5933 64.0125 10.8965 64.0125 11.3008V13.895C64.0125 13.996 63.962 14.0466 63.8609 14.0466H63.5322C63.4395 14.0466 63.3806 13.996 63.3637 13.9034L63.33 13.6507C63.1446 13.8107 62.9508 13.9287 62.7233 14.0129C62.5042 14.0971 62.2851 14.1392 62.066 14.1392H62.0576ZM62.243 13.5665C62.4115 13.5665 62.5801 13.5328 62.7654 13.4654C62.9508 13.398 63.1193 13.3054 63.2879 13.179V12.4041C63.0182 12.3368 62.7486 12.3031 62.4874 12.3031C61.9144 12.3031 61.6279 12.5221 61.6279 12.96C61.6279 13.1538 61.6784 13.297 61.788 13.4064C61.8975 13.5075 62.0492 13.5581 62.243 13.5581V13.5665Z"
        fill="#F0F1F5"
      />
      <path
        d="M66.9287 14.1215C66.4652 14.1215 66.0523 13.9615 65.7068 13.633L65.6563 13.8857C65.6394 13.9867 65.5804 14.0373 65.4793 14.0373H65.1507C65.0496 14.0373 64.999 13.9867 64.999 13.8857V8.57934C64.999 8.47827 65.0496 8.42773 65.1507 8.42773H65.5973C65.6984 8.42773 65.749 8.47827 65.749 8.57934V10.5587C66.1282 10.2386 66.541 10.0786 66.9792 10.0786C67.4679 10.0786 67.8556 10.2555 68.1421 10.6092C68.4285 10.963 68.5634 11.4515 68.5634 12.0664C68.5634 12.6812 68.4117 13.1866 68.1168 13.5572C67.8219 13.9278 67.4174 14.1215 66.9287 14.1215ZM66.7517 13.5151C67.4511 13.5151 67.7966 13.0434 67.7966 12.1085C67.7966 11.6284 67.7123 11.2746 67.5438 11.0388C67.3753 10.8029 67.114 10.6934 66.7686 10.6934C66.4231 10.6934 66.0692 10.8114 65.749 11.0388V13.1529C66.0692 13.3971 66.3978 13.5151 66.7517 13.5151Z"
        fill="#F0F1F5"
      />
      <path
        d="M70.2066 14.071C69.937 14.071 69.7348 13.9952 69.5915 13.8435C69.4483 13.6919 69.3809 13.4645 69.3809 13.1782V8.57934C69.3809 8.47827 69.4314 8.42773 69.5325 8.42773H69.9791C70.0802 8.42773 70.1308 8.47827 70.1308 8.57934V13.136C70.1308 13.254 70.1561 13.3382 70.2066 13.3887C70.2572 13.4393 70.333 13.4645 70.4426 13.4645C70.4763 13.4645 70.5016 13.4645 70.5353 13.4645C70.569 13.4645 70.5943 13.4645 70.6195 13.4645C70.6701 13.4645 70.7038 13.4814 70.7206 13.4982C70.7375 13.5235 70.7459 13.5656 70.7459 13.6077V13.852C70.7459 13.9025 70.7375 13.9446 70.7122 13.9699C70.6869 13.9952 70.6532 14.0204 70.5943 14.0373C70.4679 14.071 70.333 14.0794 70.1982 14.0794L70.2066 14.071Z"
        fill="#F0F1F5"
      />
      <path
        d="M73.1219 14.1463C72.4983 14.1463 72.0265 13.9778 71.7147 13.6325C71.3945 13.2872 71.2344 12.7818 71.2344 12.108C71.2344 11.4342 71.3945 10.9709 71.7147 10.6088C72.0349 10.2466 72.4815 10.0781 73.046 10.0781C73.5263 10.0781 73.8971 10.2129 74.1499 10.4824C74.4111 10.7519 74.5375 11.1394 74.5375 11.6279C74.5375 11.7879 74.5207 11.9564 74.4954 12.1164C74.4869 12.1754 74.4701 12.2175 74.4448 12.2343C74.4195 12.2512 74.3858 12.268 74.3353 12.268H71.9506C71.959 12.706 72.0686 13.0261 72.2708 13.2282C72.4731 13.4304 72.7932 13.5399 73.223 13.5399C73.3747 13.5399 73.5179 13.5314 73.6612 13.5062C73.8044 13.4809 73.9729 13.4388 74.192 13.3798C74.2257 13.3714 74.2594 13.363 74.2847 13.3546C74.31 13.3546 74.3353 13.3461 74.3521 13.3461C74.4195 13.3461 74.4532 13.3883 74.4532 13.4809V13.6915C74.4532 13.7589 74.4448 13.801 74.4195 13.8347C74.4027 13.8599 74.3521 13.8936 74.2931 13.9189C74.1415 13.9863 73.9645 14.0368 73.7539 14.0789C73.5432 14.121 73.3325 14.1379 73.1219 14.1379V14.1463ZM73.8465 11.7795C73.8465 11.7795 73.8465 11.67 73.8465 11.5858C73.8465 11.2742 73.7791 11.0467 73.6275 10.8867C73.4842 10.7267 73.2736 10.6509 72.9955 10.6509C72.6753 10.6509 72.4309 10.7435 72.254 10.9372C72.077 11.131 71.9759 11.4089 71.9506 11.7795H73.8381H73.8465Z"
        fill="#F0F1F5"
      />
      <path
        d="M78.1348 14.1392C77.7724 14.1392 77.4859 14.0382 77.2753 13.836C77.0646 13.6339 76.9551 13.3559 76.9551 13.0106C76.9551 12.64 77.0899 12.3452 77.3511 12.1262C77.6123 11.9072 77.9662 11.7977 78.4128 11.7977C78.6993 11.7977 79.0111 11.8398 79.365 11.924V11.4271C79.365 11.1576 79.306 10.9639 79.1796 10.8544C79.0532 10.7449 78.8426 10.6859 78.5477 10.6859C78.2022 10.6859 77.8567 10.7364 77.5281 10.8375C77.4101 10.8712 77.3427 10.888 77.309 10.888C77.25 10.888 77.2163 10.8459 77.2163 10.7533V10.5511C77.2163 10.4838 77.2247 10.4332 77.25 10.408C77.2669 10.3743 77.309 10.349 77.368 10.3237C77.5281 10.2563 77.7219 10.1974 77.9662 10.1553C78.2106 10.1132 78.4465 10.0879 78.6825 10.0879C79.1628 10.0879 79.5167 10.189 79.7442 10.3911C79.9717 10.5933 80.0897 10.8965 80.0897 11.3008V13.895C80.0897 13.996 80.0391 14.0466 79.938 14.0466H79.6094C79.5167 14.0466 79.4577 13.996 79.4409 13.9034L79.4071 13.6507C79.2218 13.8107 79.0195 13.9287 78.8004 14.0129C78.5814 14.0971 78.3623 14.1392 78.1432 14.1392H78.1348ZM78.3201 13.5665C78.4887 13.5665 78.6656 13.5328 78.8426 13.4654C79.028 13.398 79.1965 13.3054 79.365 13.179V12.4041C79.0954 12.3368 78.8257 12.3031 78.5645 12.3031C77.9915 12.3031 77.705 12.5221 77.705 12.96C77.705 13.1538 77.7556 13.297 77.8651 13.4064C77.9747 13.5075 78.1263 13.5581 78.3201 13.5581V13.5665Z"
        fill="#F0F1F5"
      />
      <path
        d="M82.3991 14.0872C81.6828 14.0872 81.3205 13.725 81.3205 13.0091V10.7771H80.7728C80.6717 10.7771 80.6211 10.7265 80.6211 10.6255V10.4317C80.6211 10.3812 80.6295 10.3475 80.6548 10.3222C80.6801 10.297 80.7222 10.2801 80.7896 10.2717L81.3458 10.2043L81.4469 9.26097C81.4553 9.1599 81.5143 9.10938 81.607 9.10938H81.9272C82.0283 9.10938 82.0789 9.1599 82.0789 9.26097V10.1875H83.0647C83.1659 10.1875 83.2164 10.238 83.2164 10.3391V10.6255C83.2164 10.7265 83.1659 10.7771 83.0647 10.7771H82.0789V12.967C82.0789 13.1523 82.121 13.287 82.2053 13.3629C82.2895 13.4387 82.4328 13.4723 82.635 13.4723C82.7277 13.4723 82.8457 13.4639 82.9889 13.4387C83.0647 13.4218 83.1153 13.4134 83.1406 13.4134C83.1827 13.4134 83.208 13.4218 83.2248 13.4471C83.2417 13.4723 83.2501 13.506 83.2501 13.5566V13.7587C83.2501 13.8261 83.2417 13.8766 83.2164 13.9103C83.1996 13.944 83.1574 13.9693 83.0984 13.9861C82.9046 14.0451 82.6687 14.0788 82.4075 14.0788L82.3991 14.0872Z"
        fill="#F0F1F5"
      />
      <path
        d="M120.891 0.995483H7.9273C3.82282 0.995483 0.495483 4.32282 0.495483 8.4273V32.2092C0.495483 36.3136 3.82282 39.641 7.9273 39.641H120.891C124.995 39.641 128.322 36.3136 128.322 32.2092V8.4273C128.322 4.32282 124.995 0.995483 120.891 0.995483Z"
        stroke="#F0F1F5"
        strokeWidth="0.990909"
      />
    </g>
    <defs>
      <clipPath id="clip0_3104_4982">
        <rect
          width="129"
          height="40"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
)
