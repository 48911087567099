export const GooglePlayStoreBadge = () => (
  <svg
    width="129"
    height="41"
    viewBox="0 0 129 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3104_5036)">
      <mask
        id="mask0_3104_5036"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="13"
        y="7"
        width="103"
        height="26">
        <path
          d="M115.441 7.99609H13.377V32.7688H115.441V7.99609Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_3104_5036)">
        <path
          d="M25.418 19.9734L13.575 8.74609C13.4777 9.02792 13.377 9.30623 13.377 9.68317V30.3588C13.377 30.7322 13.4742 31.0141 13.575 31.2959L25.418 19.977V19.9734Z"
          fill="#F0F1F5"
        />
        <path
          d="M26.7979 19.9717L30.7444 23.713L35.0869 21.3738C36.4696 20.627 36.4696 19.4081 35.0869 18.6612L30.6471 16.2305L26.7979 19.9717Z"
          fill="#F0F1F5"
        />
        <path
          d="M26.1096 19.3175L29.7608 15.7629L15.8438 8.28037C15.2532 7.99854 14.6592 7.90695 14.2666 8.09366L26.1096 19.321V19.3175Z"
          fill="#F0F1F5"
        />
        <path
          d="M26.1096 20.6309L14.2666 31.9498C14.6626 32.1365 15.2532 32.0449 15.9446 31.7631L29.9588 24.2805L26.1096 20.6309Z"
          fill="#F0F1F5"
        />
        <path
          d="M44.9595 9.53477C44.1501 9.53477 43.4622 9.82365 42.9098 10.3979C42.3575 10.9651 42.083 11.6696 42.083 12.5045C42.083 13.3395 42.3575 14.0546 42.9098 14.6183C43.4657 15.1854 44.1501 15.4708 44.9595 15.4708C46.0608 15.4708 47.0196 14.8719 47.506 13.956V12.3354H44.9491V13.1527H46.627V13.7692C46.2553 14.354 45.7168 14.6394 44.9977 14.6394C44.4106 14.6394 43.9242 14.4386 43.5491 14.0299C43.1773 13.6213 42.9863 13.1069 42.9863 12.4904C42.9863 11.8739 43.1669 11.3772 43.5317 10.9756C43.8965 10.574 44.3654 10.3767 44.9456 10.3767C45.6231 10.3767 46.1685 10.6797 46.5749 11.2821L47.2107 10.7325C46.6722 9.93286 45.9218 9.53125 44.9526 9.53125L44.9595 9.53477Z"
          fill="#F0F1F5"
        />
        <path
          d="M48.583 9.62891V15.3747H52.2724V14.5468H49.4619V12.729H51.5047V11.9293H49.4619V10.4568H52.1752V9.62891H48.5865H48.583Z"
          fill="#F0F1F5"
        />
        <path
          d="M52.7378 9.62891V10.4568H54.5721V15.3747H55.4476V10.4568H57.2923V9.62891H52.7344H52.7378Z"
          fill="#F0F1F5"
        />
        <path
          d="M60.0195 9.62891V15.3747H60.895V9.62891H60.0195Z"
          fill="#F0F1F5"
        />
        <path
          d="M61.7183 9.62891V10.4568H63.5526V15.3747H64.4281V10.4568H66.2728V9.62891H61.7148H61.7183Z"
          fill="#F0F1F5"
        />
        <path
          d="M71.5296 9.53516C70.7271 9.53516 70.0497 9.82403 69.4938 10.3983C68.938 10.9654 68.667 11.67 68.667 12.5049C68.667 13.3398 68.9414 14.0444 69.4938 14.6186C70.0462 15.1858 70.7271 15.4712 71.5296 15.4712C72.3321 15.4712 73.0026 15.1823 73.555 14.6186C74.1178 14.0444 74.3991 13.3398 74.3991 12.5049C74.3991 11.67 74.1178 10.9654 73.555 10.3983C73.0026 9.82403 72.3217 9.53516 71.5296 9.53516ZM71.54 10.3806C72.0959 10.3806 72.5649 10.5814 72.9366 10.9901C73.3083 11.3917 73.4994 11.8955 73.4994 12.4944C73.4994 13.0932 73.3083 13.6181 72.9366 14.0268C72.5649 14.4284 72.1028 14.6257 71.54 14.6257C70.9772 14.6257 70.5048 14.4249 70.133 14.0268C69.7613 13.6181 69.5807 13.1144 69.5807 12.4944C69.5807 11.8743 69.7613 11.3811 70.133 10.9795C70.5048 10.5779 70.9668 10.3806 71.54 10.3806Z"
          fill="#F0F1F5"
        />
        <path
          d="M79.0746 13.5054L75.7569 9.53516H75.4512V15.3761H76.3266V11.4939L79.6513 15.4712H79.95V9.63027H79.0746V13.5019V13.5054Z"
          fill="#F0F1F5"
        />
        <path
          d="M47.1204 19.6387C45.703 19.6387 44.5009 20.1424 43.5317 21.1465C42.5624 22.1364 42.083 23.3694 42.083 24.8314C42.083 26.2933 42.559 27.544 43.5317 28.5339C44.5009 29.5238 45.703 30.0276 47.1204 30.0276C49.045 30.0276 50.7229 28.9778 51.5775 27.3784V24.546H47.1065V25.9763H50.0455V27.0578C49.3959 28.0794 48.4544 28.5797 47.1933 28.5797C46.165 28.5797 45.3104 28.2309 44.6607 27.5123C44.0111 26.7936 43.6776 25.8988 43.6776 24.8173C43.6776 23.7358 43.9972 22.8691 44.6329 22.1681C45.2687 21.467 46.0955 21.1183 47.1065 21.1183C48.2946 21.1183 49.2499 21.6502 49.9586 22.7036L51.0738 21.7453C50.1323 20.3432 48.8157 19.6422 47.1238 19.6422L47.1204 19.6387Z"
          fill="#F0F1F5"
        />
        <path
          d="M55.9345 22.209C54.8784 22.209 53.9959 22.5895 53.2734 23.3363C52.5508 24.0831 52.1895 25.0132 52.1895 26.1088C52.1895 27.2044 52.5508 28.1344 53.2734 28.8813C53.9959 29.6281 54.8784 30.0086 55.9345 30.0227C56.9767 30.0227 57.8591 29.6422 58.5956 28.8954C59.3321 28.1344 59.7107 27.2044 59.7107 26.1088C59.7107 25.0132 59.3355 24.0831 58.5956 23.3363C57.8591 22.5895 56.9732 22.209 55.9345 22.209ZM55.9484 23.671C56.5702 23.6851 57.0913 23.914 57.5117 24.3861C57.932 24.8441 58.1474 25.4218 58.1474 26.1229C58.1474 26.8239 57.932 27.3876 57.5117 27.8596C57.0913 28.3176 56.5563 28.5466 55.9345 28.5466C55.3126 28.5466 54.7776 28.3176 54.3572 27.8596C53.9369 27.3876 53.7354 26.8098 53.7354 26.1229C53.7354 25.4359 53.9369 24.8441 54.3572 24.3861C54.7776 23.914 55.3126 23.6851 55.9484 23.671Z"
          fill="#F0F1F5"
        />
        <path
          d="M64.0292 22.209C62.9731 22.209 62.0907 22.5895 61.3681 23.3363C60.6455 24.0831 60.2842 25.0132 60.2842 26.1088C60.2842 27.2044 60.6455 28.1344 61.3681 28.8813C62.0907 29.6281 62.9731 30.0086 64.0292 30.0227C65.0714 30.0227 65.9538 29.6422 66.6938 28.8954C67.4303 28.1344 67.8089 27.2044 67.8089 26.1088C67.8089 25.0132 67.4337 24.0831 66.6938 23.3363C65.9573 22.5895 65.0714 22.209 64.0292 22.209ZM64.0431 23.671C64.6649 23.6851 65.186 23.914 65.6064 24.3861C66.0268 24.8441 66.2421 25.4218 66.2421 26.1229C66.2421 26.8239 66.0268 27.3876 65.6064 27.8596C65.186 28.3176 64.651 28.5466 64.0292 28.5466C63.4073 28.5466 62.8723 28.3176 62.452 27.8596C62.0316 27.3876 61.8301 26.8098 61.8301 26.1229C61.8301 25.4359 62.0316 24.8441 62.452 24.3861C62.8723 23.914 63.4073 23.6851 64.0431 23.671Z"
          fill="#F0F1F5"
        />
        <path
          d="M74.3953 23.4459C73.8325 22.6251 72.9917 22.2129 71.8766 22.2129C70.8795 22.2129 70.0527 22.5617 69.4031 23.2627C68.7534 23.9638 68.4199 24.8621 68.4199 25.9577C68.4199 27.0533 68.7534 27.9516 69.4031 28.6527C70.0527 29.3537 70.8795 29.7025 71.8766 29.7025C72.8736 29.7025 73.7005 29.3537 74.2806 28.6527V29.1705C74.2806 30.5726 73.4399 31.3019 72.1545 31.3019C71.3312 31.3019 70.4904 30.9531 69.6636 30.2344L68.8229 31.3477C69.7783 32.2918 70.9212 32.7638 72.2379 32.7638C74.3953 32.7638 75.7988 31.4393 75.7988 29.0789V22.3785H74.3953V23.4459ZM72.1545 28.3039C71.5327 28.3039 71.0254 28.089 70.6051 27.6628C70.1847 27.2224 69.9832 26.6587 69.9832 25.9577C69.9832 25.2566 70.1847 24.7071 70.6051 24.2808C71.0254 23.8545 71.5327 23.6396 72.1545 23.6396C73.3253 23.6396 74.298 24.4147 74.298 25.9401C74.298 26.701 74.0826 27.2788 73.6622 27.6909C73.2419 28.1031 72.752 28.3004 72.158 28.3004L72.1545 28.3039Z"
          fill="#F0F1F5"
        />
        <path
          d="M77.2715 18.4336V29.8582H78.7896V18.4336H77.2715Z"
          fill="#F0F1F5"
        />
        <path
          d="M86.7764 25.6367C86.7764 24.6468 86.4603 23.8401 85.835 23.1848C85.2131 22.5296 84.4314 22.209 83.49 22.209C82.4617 22.209 81.5793 22.5895 80.8706 23.3504C80.1619 24.0972 79.8145 25.0273 79.8145 26.1229C79.8145 27.2185 80.1758 28.1626 80.8983 28.9095C81.6209 29.6563 82.5346 30.0227 83.6324 30.0227C84.7302 30.0227 85.6578 29.6281 86.4707 28.8179L85.7481 27.7223C85.1124 28.3176 84.4175 28.6065 83.6776 28.6065C82.4478 28.6065 81.5515 27.8737 81.3604 26.6407H86.6583C86.7313 26.292 86.7729 25.9538 86.7729 25.6367H86.7764ZM83.4344 23.5653C84.4627 23.5653 85.2444 24.2804 85.2583 25.3936H81.3951C81.6279 24.2804 82.4373 23.5653 83.4344 23.5653Z"
          fill="#F0F1F5"
        />
        <path
          d="M91.2305 19.8047V29.8554H92.766V25.9732H94.7184C95.6877 25.9732 96.4693 25.6843 97.0634 25.1207C97.6574 24.5429 97.9597 23.7961 97.9597 22.8801C97.9597 21.9642 97.6574 21.2209 97.0634 20.6572C96.4693 20.0936 95.6877 19.8047 94.7184 19.8047H91.2305ZM94.6871 21.2385C95.771 21.2385 96.3825 21.9078 96.3825 22.8978C96.3825 23.8877 95.7745 24.5429 94.6871 24.5429H92.7625V21.2385H94.6871Z"
          fill="#F0F1F5"
        />
        <path
          d="M98.8428 18.4336V29.8582H100.361V18.4336H98.8428Z"
          fill="#F0F1F5"
        />
        <path
          d="M104.571 22.209C103.282 22.209 102.285 22.7409 101.577 23.8084L102.459 24.7525C103.053 24.0056 103.717 23.6393 104.425 23.6393C105.394 23.6393 105.943 24.2029 105.943 25.2386V25.7248H103.932C102.327 25.7248 101.312 26.5632 101.312 27.8561C101.312 29.149 102.296 30.0192 103.786 30.0192C104.769 30.0192 105.523 29.6845 106.058 29.0151V29.8536H107.475V25.3302C107.475 23.3187 106.319 22.209 104.568 22.209H104.571ZM104.109 28.7897C103.369 28.7897 102.865 28.4233 102.865 27.7998C102.865 27.1762 103.286 26.8873 104.123 26.8873H105.947V27.0247C105.947 28.0464 105.137 28.7932 104.109 28.7932V28.7897Z"
          fill="#F0F1F5"
        />
        <path
          d="M111.72 27.8323L109.681 22.3789H108.045L110.883 29.5233L110.595 30.2243C110.247 31.0627 109.754 31.3199 109.045 31.3199C108.931 31.3199 108.656 31.3058 108.205 31.26V32.6163C108.594 32.722 108.986 32.7678 109.375 32.7678C110.591 32.7678 111.387 32.1266 111.981 30.7105L115.441 22.3789H113.819L111.72 27.8323Z"
          fill="#F0F1F5"
        />
      </g>
      <path
        d="M120.891 1.05994H7.9273C3.82282 1.05994 0.495483 4.38728 0.495483 8.49176V32.2736C0.495483 36.3781 3.82282 39.7054 7.9273 39.7054H120.891C124.995 39.7054 128.322 36.3781 128.322 32.2736V8.49176C128.322 4.38728 124.995 1.05994 120.891 1.05994Z"
        stroke="#F0F1F5"
        strokeWidth="0.990909"
      />
    </g>
    <defs>
      <clipPath id="clip0_3104_5036">
        <rect width="129" height="41" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
