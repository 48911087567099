import { defaultIconProps } from 'components/common/icons'
import type { DefaultIconProps } from 'components/common/icons'

export const LinkedInIconV2 = ({
  size = defaultIconProps.size,
  className = defaultIconProps.className,
}: DefaultIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={size}
    height={size}
    stroke="none"
    fill="currentColor"
    className={className}>
    <path d="M5.00006 9H4.50006C3.55725 9 3.08585 9 2.79295 9.29289C2.50006 9.58579 2.50006 10.0572 2.50006 11V19.5C2.50006 20.4428 2.50006 20.9142 2.79295 21.2071C3.08585 21.5 3.55725 21.5 4.50006 21.5H5.00006C5.94287 21.5 6.41427 21.5 6.70717 21.2071C7.00006 20.9142 7.00006 20.4428 7.00006 19.5V11C7.00006 10.0572 7.00006 9.58579 6.70717 9.29289C6.41427 9 5.94287 9 5.00006 9Z" />
    <path d="M7.00006 4.75C7.00006 5.99264 5.9927 7 4.75006 7C3.50742 7 2.50006 5.99264 2.50006 4.75C2.50006 3.50736 3.50742 2.5 4.75006 2.5C5.9927 2.5 7.00006 3.50736 7.00006 4.75Z" />
    <path d="M11.8261 9H11.0001C10.0573 9 9.58585 9 9.29295 9.29289C9.00006 9.58579 9.00006 10.0572 9.00006 11V19.5C9.00006 20.4428 9.00006 20.9142 9.29295 21.2071C9.58585 21.5 10.0573 21.5 11.0001 21.5H11.5001C12.4429 21.5 12.9143 21.5 13.2072 21.2071C13.5001 20.9142 13.5001 20.4428 13.5001 19.5L13.5002 16.0001C13.5002 14.3433 14.0282 13.0001 15.588 13.0001C16.3678 13.0001 17.0001 13.6717 17.0001 14.5001V19.0001C17.0001 19.9429 17.0001 20.4143 17.293 20.7072C17.5858 21.0001 18.0573 21.0001 19.0001 21.0001H19.4988C20.4414 21.0001 20.9127 21.0001 21.2056 20.7073C21.4985 20.4145 21.4986 19.9432 21.4988 19.0006L21.5002 13.5002C21.5002 11.015 19.1365 9.00024 16.7969 9.00024C15.465 9.00024 14.2768 9.65309 13.5002 10.674C13.5001 10.0439 13.5001 9.72893 13.3633 9.495C13.2766 9.34686 13.1532 9.22353 13.0051 9.13687C12.7712 9 12.4562 9 11.8261 9Z" />
  </svg>
)
