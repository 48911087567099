import { isFirefox } from 'util/Device'
import { useEffect } from 'react'

const onClick = (e: MouseEvent) => {
  // remove global style for focus if the event was a mouse event not some weird pointer event
  if (e?.detail > 0 && document.body.classList.contains('show-focus')) {
    document.body.classList.remove('show-focus')
  }
}

const onKeyUp = (e: KeyboardEvent) => {
  // add global style for focus
  // isFirefox() handles when a user is using accessibility to tab to and focus
  // see for more details: https://midwesttapes.atlassian.net/wiki/spaces/WWW/pages/1281327188/01+09+2023+-+Firefox+Accessibility
  if (
    (e.shiftKey && e.key === 'Tab') ||
    e.key === 'Tab' ||
    (isFirefox() && e.key === 'Enter')
  ) {
    if (!document.body.classList.contains('show-focus')) {
      document.body.classList.add('show-focus')
    }
  }
}

export const useAppEventHandlers = () => {
  useEffect(() => {
    window.addEventListener('click', onClick)
    window.addEventListener('keyup', onKeyUp)

    return () => {
      window.removeEventListener('click', onClick)
      window.removeEventListener('keyup', onKeyUp)
    }
  })
}
