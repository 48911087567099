import { defaultIconProps } from 'components/common/icons'
import type { DefaultIconProps } from 'components/common/icons'

export const ForwardExtendIcon = ({
  size = defaultIconProps.size,
  className = defaultIconProps.className,
}: DefaultIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={size}
    height={size}
    stroke="none"
    fill="currentColor"
    className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 12C21 11.4477 20.5523 11 20 11L4 11C3.44772 11 3 11.4477 3 12C3 12.5523 3.44772 13 4 13L20 13C20.5523 13 21 12.5523 21 12Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.7071 12.7071C21.0976 12.3166 21.0976 11.6834 20.7071 11.2929L15.7071 6.2929C15.3166 5.90237 14.6835 5.90237 14.2929 6.29289C13.9024 6.68341 13.9024 7.31658 14.2929 7.7071L18.5858 12L14.2929 16.2929C13.9024 16.6834 13.9024 17.3166 14.2929 17.7071C14.6834 18.0976 15.3166 18.0976 15.7071 17.7071L20.7071 12.7071Z"
    />
  </svg>
)
