import branch from 'branch-sdk'
import Bugsnag from '@bugsnag/js'

export const useBranch = () => {
  var options = { no_journeys: true }

  const initializeBranch = () => {
    branch.init('%REACT_APP_BRANCH_API_KEY%', options, (err) => {
      if (err) {
        Bugsnag.leaveBreadcrumb('Branch initialization failed')
      }
    })
  }
  const trackBranchEvent = (
    event: string,
    event_data_and_custom_data?: {},
    callback?: {}[],
  ) => {
    if (!event) return

    try {
      branch.logEvent(event, event_data_and_custom_data, callback)
    } catch (error) {
      Bugsnag.leaveBreadcrumb('Failed to send Branch event', {
        error,
      })
    }
  }

  return { initializeBranch, trackBranchEvent }
}
