import { FC, useCallback } from 'react'
import KidsModeOff from 'components/common/icons/kids-mode/KidsModeOff'
import KidsModeOn from 'components/common/icons/kids-mode/KidsModeOn'
import classNames from 'classnames'
import Tooltip from 'components/common/Tooltip'

type KidsModeToggleButtonUIProps = {
  /** Accessible label for the link */
  ariaLabel: string

  className?: string

  height?: number

  /** Indicates if Kids Mode is currently enabled */
  isKidsMode: boolean

  onClick?: () => void
}

/**
 * [Presentational component](https://www.patterns.dev/react/presentational-container-pattern#presentational-component)
 * that renders a toggle button to turn Kids Mode on or off. It should receive all relevant props
 * from the container component.
 */
const KidsModeToggleButtonUI: FC<KidsModeToggleButtonUIProps> = ({
  ariaLabel,
  className,
  isKidsMode,
  onClick,
}) => {
  const renderIcon = useCallback(() => {
    return isKidsMode ? (
      <KidsModeOn height={18} className="text-text-white-1-light" />
    ) : (
      <KidsModeOff height={18} className="text-text-white-1-light" />
    )
  }, [isKidsMode])

  return (
    <Tooltip label={ariaLabel}>
      <button
        aria-label={ariaLabel}
        aria-pressed={isKidsMode}
        className={classNames(
          'flex rounded-lg px-2 pt-[0.5625rem] pb-[0.3125rem] text-text-white-1-light focus-visible:focus-outline',
          className,
        )}
        onClick={onClick}>
        {renderIcon()}
      </button>
    </Tooltip>
  )
}

export default KidsModeToggleButtonUI
