import { ReactNode } from 'react'
import classNames from 'classnames'
import { BaseTooltip, TooltipContent, TooltipTrigger } from './BaseTooltip'

type TooltipProps = {
  children: ReactNode
  className?: string
  label: string
  rootId?: string
}

const Tooltip = ({ children, className, label }: TooltipProps) => {
  return (
    <BaseTooltip>
      <TooltipTrigger asChild={true}>{children}</TooltipTrigger>
      <TooltipContent
        data-testid="tooltip"
        className={classNames(
          'z-100 w-max rounded-sm bg-primary-dark-1-light px-2 py-1 text-sm text-text-white-1-light',
          'dark:bg-primary-dark-1-dark dark:text-text-white-1-dark',
          className,
        )}>
        {label}
      </TooltipContent>
    </BaseTooltip>
  )
}

export default Tooltip
