import { useEffect, useRef, useState, FC } from 'react'
import config from 'config'
import { useLibrary } from 'state/useLibrary'
import { Player } from '@lottiefiles/react-lottie-player'
import loadingAnimation from 'images/lotties/splash-screen-loading.json'
import HooplaWhite from 'images/Hoopla_white_no_r.png'

const animateRemoveLoader = () => {
  const loadingScreen = document.getElementById('loading-screen')
  const svgLoader = document.getElementById('svg-loader')

  loadingScreen && loadingScreen.classList.add('complete')
  svgLoader && svgLoader.setAttribute('fill', '#fff')
}

type BrandedSplashScreenProps = {
  children: JSX.Element
}

const BrandedSplashScreen: FC<BrandedSplashScreenProps> = ({ children }) => {
  const { librarySplashSettings, library } = useLibrary()
  const [isShowingSplashScreen, showSplashScreen] = useState(true)

  const timerId = useRef<ReturnType<typeof setTimeout> | null>()

  useEffect(() => {
    if (!isShowingSplashScreen) return
    animateRemoveLoader()

    timerId.current = setTimeout(() => {
      showSplashScreen(false)
      timerId.current = null
    }, 2000)
    return () => clearTimeout(timerId.current ?? 0)
  }, [librarySplashSettings?.seconds, isShowingSplashScreen])

  if (!isShowingSplashScreen) return children

  const splashScreenStyles = {
    backgroundColor: librarySplashSettings?.backgroundColor ?? '',
    color: librarySplashSettings?.textColor ?? '',
  }

  const LogoUrlDecider = () => {
    console.log('branded')
    const libraryLogoUrl = librarySplashSettings?.imageName
      ? `${config.librarySplashUrl}${librarySplashSettings?.imageName}`
      : undefined

    if (!!librarySplashSettings?.imageName) {
      return (
        <img
          src={libraryLogoUrl}
          alt="library logo"
          className="h-auto max-h-[255px] w-auto max-w-[355px]"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null // prevents looping
          }}
        />
      )
    }

    if (!library?.name) {
      return (
        <img
          src={HooplaWhite}
          alt="library logo"
          className="h-auto max-h-[255px] w-auto max-w-[355px]"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null // prevents looping
          }}
        />
      )
    }

    return (
      <>
        <span className="text-5xl">{library.name}</span>
        <br></br>
      </>
    )
  }

  return (
    <div
      className="hoopla-white-HeaderV2 fixed flex h-full w-full flex-col bg-primary-blue-grey-light p-4 text-text-white-1-light"
      style={splashScreenStyles}>
      <div className="m-auto flex flex-col items-center space-y-2 md:space-y-4">
        <div>
          <LogoUrlDecider />
        </div>
        <div className="fixed bottom-0 w-full">
          <img
            alt={'rounded background'}
            src="/images/round-background-light.svg"
            className=" h-[160px] w-full object-cover"
          />
          <img
            className="absolute top-1/2 left-1/2 z-10 h-[28px] w-[183px] -translate-x-1/2 -translate-y-1/2 transform"
            alt={'powered by hoopla'}
            src="/images/hoopla-white-label-light.svg"
          />
          <Player
            src={loadingAnimation}
            className="absolute top-3/4 left-1/2 z-10 h-[4px] w-[160px] -translate-x-1/2 -translate-y-1/2 transform"
            loop
            autoplay
          />
        </div>
      </div>
    </div>
  )
}

export default BrandedSplashScreen
