import { defaultIconProps } from 'components/common/icons'
import type { DefaultIconProps } from 'components/common/icons'

export const TikTokIconV2 = ({
  size = defaultIconProps.size,
  className = defaultIconProps.className,
}: DefaultIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={size}
    height={size}
    stroke="none"
    fill="currentColor"
    className={className}>
    <path d="M9.50006 22C12.8138 22 15.5001 19.3137 15.5001 16V8.24537C16.7482 9.24588 18.3026 9.88001 20.0004 9.98464C20.276 10.0016 20.5001 9.77614 20.5001 9.5V7.5C20.5001 7.22386 20.2756 7.00263 20.0008 6.97538C17.638 6.74113 15.759 4.86207 15.5247 2.4993C15.4975 2.2245 15.2762 2 15.0001 2H13.0001C12.724 2 12.5001 2.22386 12.5001 2.5V16C12.5001 17.6569 11.157 19 9.50006 19C7.84321 19 6.50006 17.6569 6.50006 16C6.50006 14.5128 7.58227 13.2783 9.00215 13.0411C9.27452 12.9956 9.50006 12.7761 9.50006 12.5V10.5C9.50006 10.2239 9.27542 9.99783 9.00021 10.0205C5.92037 10.2745 3.50006 12.8546 3.50006 16C3.50006 19.3137 6.18635 22 9.50006 22Z" />
  </svg>
)
