import create, { GetState, SetState, State } from 'zustand'
import { useCallback, useMemo } from 'react'
import sortBy from 'lodash/sortBy'
import Bugsnag, { NotifiableError } from '@bugsnag/js'

import { api, api as titleRequestsApi } from 'api/titleRequestsApi'
import { AxiosResponse } from 'axios'
import { HoldStatus } from 'types/common'
import { Status } from 'types/hoopla'
import useHootie from 'components/hootie/useHootie'
import { useUser } from './useUser'

import type { TitleRequest } from 'types/common'

interface UseTitleRequestsState extends State {
  status: string | undefined
  titleRequests: TitleRequest[]
  isLoadingTitleRequests: boolean
  addRequest: (
    contentId: number,
    patronId: string,
    hooplaUserId: string,
    updateHootieTitleStatus: (titleId: string, status: Status) => void,
  ) => Promise<TitleRequest | undefined>
  deleteRequest: (
    titleRequestId: string,
    patronId: string,
    hooplaUserId: string,
    updateHootieTitleStatus: (titleId: string, status: Status) => void,
  ) => Promise<void>
  fetchAll: (
    hooplaUserId: string,
    forceReload: boolean,
  ) => Promise<TitleRequest[]>
  fetchPatronStatus: (
    hooplaUserId: string,
    patronId: string,
    forceReload: boolean,
  ) => Promise<string | undefined>
  hideRequest: (
    titleRequestId: string,
    patronId: string,
    hooplaUserId: string,
  ) => void
  teardown: () => void
}

const defaultState: {
  status: string | undefined
  titleRequests: TitleRequest[]
} = {
  status: undefined,
  titleRequests: [],
}

const useState = create<UseTitleRequestsState>(
  (
    set: SetState<UseTitleRequestsState>,
    get: GetState<UseTitleRequestsState>,
  ) => ({
    status: defaultState.status,
    titleRequests: defaultState.titleRequests,
    isLoadingTitleRequests: false,

    addRequest: async (
      contentId: number,
      patronId: string,
      hooplaUserId: string,
      updateHootieTitleStatus: (titleId: string, status: Status) => void,
    ) => {
      Bugsnag.leaveBreadcrumb(
        'Add title request',
        { contentId, patronId, hooplaUserId },
        'process',
      )

      const response: AxiosResponse<TitleRequest> = await api.addTitleRequest(
        hooplaUserId,
        patronId,
        contentId,
      )

      const titleRequest = response?.data

      if (titleRequest) {
        await get().fetchPatronStatus(hooplaUserId, patronId, true)

        const cached = get().titleRequests

        cached.unshift(titleRequest)

        set({ titleRequests: cached })
        updateHootieTitleStatus(contentId.toString(), Status.Requested)
        return titleRequest
      }

      return
    },
    deleteRequest: async (
      titleRequestId: string,
      patronId: string,
      hooplaUserId: string,
      updateHootieTitleStatus: (titleId: string, status: Status) => void,
    ) => {
      try {
        Bugsnag.leaveBreadcrumb(
          'Delete title request',
          { titleRequestId, patronId, hooplaUserId },
          'process',
        )

        await titleRequestsApi.deleteTitleRequest(
          hooplaUserId,
          patronId,
          titleRequestId,
        )

        const cached = get().titleRequests

        const updatedItem = cached.find(
          (titleRequest) => titleRequest.id === titleRequestId,
        )
        const updated = cached.filter(
          (titleRequest) => titleRequest.id !== titleRequestId,
        )

        set({ titleRequests: updated })

        if (updatedItem) {
          updateHootieTitleStatus(
            updatedItem.contentId.toString(),
            Status.Request,
          )
        }

        await get().fetchPatronStatus(hooplaUserId, patronId, true)
      } catch (error) {
        Bugsnag.notify(error as NotifiableError)
      }
    },
    fetchAll: async (hooplaUserId: string, forceReload: boolean = false) => {
      set({ isLoadingTitleRequests: true })

      const cached = get().titleRequests

      if (forceReload || cached.length === 0) {
        const response: AxiosResponse<TitleRequest[]> =
          await titleRequestsApi.fetchTitleRequests(hooplaUserId)

        const titleRequests = sortBy(response.data, 'inserted').reverse()

        set({ titleRequests, isLoadingTitleRequests: false })

        return titleRequests
      }

      set({ isLoadingTitleRequests: false })

      return cached
    },
    fetchPatronStatus: async (
      hooplaUserId: string,
      patronId: string,
      forceReload: boolean,
    ) => {
      try {
        const cached: string | undefined = get().status

        if (forceReload || !cached) {
          const response: AxiosResponse<{
            currentTitleRequestMessage: string
          }> = await titleRequestsApi.getTitleRequestsStatus(
            hooplaUserId,
            patronId,
          )

          const status = response?.data?.currentTitleRequestMessage

          set({ status })

          return status
        }

        return cached
      } catch (error) {
        Bugsnag.notify(error as NotifiableError)
      }
    },
    hideRequest: async (
      hooplaUserId: string,
      patronId: string,
      titleRequestId: string,
    ) => {
      Bugsnag.leaveBreadcrumb(
        'Hide title request',
        { hooplaUserId, patronId, titleRequestId },
        'process',
      )

      try {
        await api.hideTitleRequest(hooplaUserId, patronId, titleRequestId)

        const cached = get().titleRequests

        const updated = cached.filter(
          (titleRequest) => titleRequest.id !== titleRequestId,
        )

        set({ titleRequests: updated })

        await get().fetchPatronStatus(hooplaUserId, patronId, true)
      } catch (error) {
        Bugsnag.notify(error as NotifiableError)
      }
    },
    teardown: (): void => {
      set({
        titleRequests: defaultState.titleRequests,
        status: defaultState.status,
      })
    },
  }),
)

interface UseTitleRequestsResponse {
  activeTitleRequests: TitleRequest[]
  addTitleRequest: (
    contentId: number,
  ) => Promise<TitleRequest | undefined | void>
  deleteTitleRequest: (titleRequestId: string) => Promise<TitleRequest | void>
  fetchAllTitleRequests: (
    forceReload?: boolean,
  ) => TitleRequest[] | Promise<TitleRequest[]>
  fetchPatronTitleRequestStatus: (
    forceReload: boolean,
  ) => string | undefined | Promise<string | undefined>
  getTitleRequest: (titleId: number) => TitleRequest | null | undefined
  hideTitleRequest: (titleRequestId: string) => void
  isLoadingTitleRequests: boolean
  pastTitleRequests: TitleRequest[]
  patronTitleRequestStatus: UseTitleRequestsState['status']
  teardownTitleRequests: UseTitleRequestsState['teardown']
  titleRequests: TitleRequest[]
}

export function useTitleRequests(): UseTitleRequestsResponse {
  const user = useUser((state) => state.user)

  const addRequest = useState((state) => state.addRequest)
  const deleteRequest = useState((state) => state.deleteRequest)
  const isLoadingTitleRequests = useState(
    (state) => state.isLoadingTitleRequests,
  )
  const titleRequests = useState((state) => state.titleRequests)
  const fetchAll = useState((state) => state.fetchAll)
  const fetchPatronStatus = useState((state) => state.fetchPatronStatus)
  const hideRequest = useState((state) => state.hideRequest)
  const patronTitleRequestStatus = useState((state) => state.status)
  const teardownTitleRequests = useState((state) => state.teardown)
  const { updateHootieTitleStatus } = useHootie()

  const addTitleRequest = useCallback(
    (contentId: number) => {
      if (contentId && user) {
        return addRequest(
          contentId,
          user.patrons[0].id,
          user.id,
          updateHootieTitleStatus,
        )
      } else {
        return Promise.resolve()
      }
    },
    [user, addRequest, updateHootieTitleStatus],
  )

  const deleteTitleRequest = useCallback(
    (titleRequestId: string) => {
      if (titleRequestId && user) {
        return deleteRequest(
          titleRequestId,
          user.patrons[0].id,
          user.id,
          updateHootieTitleStatus,
        )
      } else {
        return Promise.resolve()
      }
    },
    [user, deleteRequest, updateHootieTitleStatus],
  )

  const getTitleRequest = useCallback(
    (titleId: number) => {
      if (titleId && user) {
        return titleRequests.find((request) => request.titleId === titleId)
      } else {
        return null
      }
    },
    [titleRequests, user],
  )

  const fetchAllTitleRequests = useCallback(
    (forceReload: boolean = false) => {
      if (!user?.id) {
        return defaultState.titleRequests
      } else {
        return fetchAll(user.id, forceReload)
      }
    },
    [fetchAll, user],
  )

  const fetchPatronTitleRequestStatus = useCallback(
    (
      forceReload: boolean,
    ): string | undefined | Promise<string | undefined> => {
      if (!user?.id) {
        return defaultState.status
      } else {
        return fetchPatronStatus(user.id, user.patrons[0].id, forceReload)
      }
    },
    [fetchPatronStatus, user],
  )

  const getActiveTitleRequests = useMemo(
    () =>
      function (titleRequests: TitleRequest[]) {
        return titleRequests.filter(
          (item: TitleRequest) =>
            item.status !== HoldStatus.Denied &&
            item.status !== HoldStatus.Approved,
        )
      },
    [],
  )

  const getPastTitleRequests = useMemo(
    () =>
      function (titleRequests: TitleRequest[]) {
        return titleRequests.filter(
          (item: TitleRequest) =>
            item.status === HoldStatus.Denied ||
            item.status === HoldStatus.Approved,
        )
      },
    [],
  )

  const hideTitleRequest = (titleRequestId: string) => {
    if (titleRequestId && user) {
      return hideRequest(user.id, user.patrons[0].id, titleRequestId)
    }
  }

  return {
    activeTitleRequests: getActiveTitleRequests(titleRequests),
    addTitleRequest,
    deleteTitleRequest,
    fetchAllTitleRequests,
    fetchPatronTitleRequestStatus,
    getTitleRequest,
    hideTitleRequest,
    isLoadingTitleRequests,
    pastTitleRequests: getPastTitleRequests(titleRequests),
    patronTitleRequestStatus,
    teardownTitleRequests,
    titleRequests,
  }
}
