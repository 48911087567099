import { defaultIconProps } from 'components/common/icons'
import type { DefaultIconProps } from 'components/common/icons'

export const FacebookIconV2 = ({
  size = defaultIconProps.size,
  className = defaultIconProps.className,
}: DefaultIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={size}
    height={size}
    fill="currentColor"
    stroke="none"
    className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.78195 2.66984C10.7715 1.71255 12.1342 1.25 13.6431 1.25H17.9995C18.4138 1.25 18.7501 1.58579 18.7501 2V5.5C18.7501 5.91421 18.4143 6.25 18.0001 6.25H15.0001C14.0912 6.25 13.913 6.47208 13.8722 6.53267C13.8352 6.58757 13.7976 6.68012 13.7748 6.85151C13.7513 7.02923 13.7501 7.23159 13.7501 7.5V8.75H18.0001C18.2311 8.75 18.4491 8.8564 18.5913 9.03844C18.7334 9.22048 18.7837 9.45785 18.7277 9.6819L17.7277 13.6819C17.6442 14.0158 17.3443 14.25 17.0001 14.25H13.7501V22C13.7501 22.4142 13.4143 22.75 13.0001 22.75H9.00006C8.58585 22.75 8.25006 22.4142 8.25006 22V14.25H5.00006C4.58585 14.25 4.25006 13.9142 4.25006 13.5V9.5C4.25006 9.08579 4.58585 8.75 5.00006 8.75H8.25006V6.7C8.25006 5.01549 8.78795 3.63147 9.78195 2.66984Z"
    />
  </svg>
)
