// ABSOLUTE IMPORTS
import Footer from 'components/browse/skeletonLoaders/MainLayout/Footer'
import Header from 'components/browse/skeletonLoaders/MainLayout/Header'

function MainLayoutSkeletonLoader() {
  return (
    <div className="flex h-full flex-col">
      <Header />
      <div className="mx-auto h-full w-[calc(100%-3rem)] md:max-w-245 lg:w-full" />
      <Footer />
    </div>
  )
}

export default MainLayoutSkeletonLoader
