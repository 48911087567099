import { MouseEvent, useMemo } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

const defaultClasses = (additionalClasses = '', isOutline = false) => {
  return classNames(
    'inline-block rounded-md disabled:cursor-not-allowed transition-colors py-3 px-8 h-auto w-auto leading-none disabled:opacity-50 text-xs tracking-wider text-center uppercase font-semibold',
    {
      'bg-transparent hover:bg-hoopla-blue focus:bg-hoopla-blue text-hoopla-blue hover:text-white focus:text-white border border-solid border-hoopla-blue':
        isOutline,
    },
    {
      'bg-hoopla-blue hover:bg-hoopla-blue-dark focus:bg-hoopla-blue-dark text-white hover:text-white focus:text-white':
        !isOutline,
    },
    additionalClasses,
  )
}

interface PrimaryButtonProps {
  children: React.ReactNode
  className?: string
  disabled?: boolean
  id?: string
  isOutline?: boolean
  onClick?: (e: MouseEvent) => void
  type?: 'button' | 'submit'
}

export function PrimaryButton({
  children,
  type = 'button',
  id = '',
  isOutline = false,
  className = '',
  disabled = false,
  onClick,
}: PrimaryButtonProps) {
  const classes = useMemo(
    () => defaultClasses(className, isOutline),
    [className, isOutline],
  )

  return (
    <button
      id={id}
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={classes}>
      {children}
    </button>
  )
}

interface PrimaryButtonInternalLinkProps {
  children: JSX.Element | string
  className?: string
  onClick?: () => void
  state?: { prevLocation: any }
  to: string
  ariaLabel?: string
}

export function PrimaryButtonInternalLink({
  children,
  to,
  className = '',
  onClick = () => {},
  state,
  ariaLabel,
}: PrimaryButtonInternalLinkProps) {
  const classes = useMemo(() => defaultClasses(className), [className])

  return (
    <Link
      to={to}
      state={state}
      className={classes}
      onClick={onClick}
      aria-label={ariaLabel}>
      {children}
    </Link>
  )
}

interface PrimaryButtonExternalLinkProps {
  children: JSX.Element
  href: string
  className?: string
}

export function PrimaryButtonExternalLink({
  children,
  href,
  className = '',
}: PrimaryButtonExternalLinkProps) {
  const classes = useMemo(() => defaultClasses(className), [className])

  return (
    <a
      href={href}
      className={classes}
      target="_blank"
      rel="noopener noreferrer">
      {children}
    </a>
  )
}
