import { defaultIconProps } from 'components/common/icons'
import type { DefaultIconProps } from 'components/common/icons'

export const TwitterIconV2 = ({
  size = defaultIconProps.size,
  className = defaultIconProps.className,
}: DefaultIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={size}
    height={size}
    stroke="currentColor"
    fill="none"
    className={className}>
    <path
      d="M3.00006 21L10.5485 13.4516M10.5485 13.4516L3.00006 3H8.00006L13.4517 10.5484M10.5485 13.4516L16.0001 21H21.0001L13.4517 10.5484M21.0001 3L13.4517 10.5484"
      strokeWidth="1.5"
      strokeLinecap="square"
    />
  </svg>
)
