import isEqual from 'lodash/isEqual'
import keyBy from 'lodash/keyBy'
import create, { GetState, SetState, State } from 'zustand'
import { KindName } from 'types/hoopla'
import { graphql } from 'graphql/hoopla/generated'
import { client } from '../graphql/client'

import type { KindExt } from 'types/hoopla'

export type KindResponse = KindExt | null | undefined

interface KindState extends State {
  enabledKinds: KindExt[]
  enabledKindsV2: KindExt[]
  fetch: () => void
  getKindById: (kindId: string) => KindResponse
  getKindByName: (kindName: KindName) => KindResponse
  getKindsEnabledByName: () => { [kindName: string]: KindExt }
  getKindsEnabledByNameV2: () => { [kindName: string]: KindExt }
  getKindsByName: () => { [kindName: string]: KindExt }
  orderedKinds: KindExt[]
  orderedKindsV2: KindExt[]
  refetch: () => void
  save: (
    enabledKinds: KindExt[],
    enabledKindsV2: KindExt[],
    orderedKinds: KindExt[],
    orderedKindsV2: KindExt[],
  ) => void
}

const GetKindsQueryDocument = graphql(/* gql_hoopla */ `
  query GetKindsQuery {
    kinds {
      enabled
      id
      name
      plural
      singular
    }
  }
`)

// this is the order kinds should be displayed when shown in
// lists or groups (per MWT)
export const ORDERED_KINDS = [
  KindName.Audiobook,
  KindName.Comic,
  KindName.Ebook,
  KindName.Movie,
  KindName.Music,
  KindName.Television,
  KindName.BingePass,
]

// this is the new order kinds should be displayed when shown in
// lists or groups (per MWT)
export const ORDERED_KINDS_V2 = [
  KindName.Audiobook, // KindId: 8
  KindName.Ebook, // KindId: 5
  KindName.Movie, // KindId: 7
  KindName.Television, // KindId: 9
  KindName.Comic, // KindId: 10
  KindName.BingePass, // KindId: 11
  KindName.Music, //KindId: 6
]

export function getSlugForKind(kind: KindExt): string {
  if (kind.name === KindName.BingePass) {
    return 'binge'
  } else {
    return kind.name.toLowerCase()
  }
}

export const useKinds = create(
  (set: SetState<KindState>, get: GetState<KindState>): KindState => ({
    enabledKinds: [],
    enabledKindsV2: [],
    fetch: async () => {
      const { data } = await client.query({
        query: GetKindsQueryDocument,
        fetchPolicy: 'cache-first',
      })

      const kinds = data?.kinds as KindExt[]
      const kinds2 = data?.kinds as KindExt[]

      if (kinds) {
        const orderedKinds = ORDERED_KINDS.map((kindName: string) =>
          kinds.find((kind: KindExt) => kind.name === kindName),
        )
          .filter((kind) => !!kind)
          .map((k) => ({ ...k, slug: getSlugForKind(k as KindExt) }))

        const orderedKindsV2 = ORDERED_KINDS_V2.map((kindName: string) =>
          kinds2.find((kinds2: KindExt) => kinds2.name === kindName),
        )
          .filter((kinds2) => !!kinds2)
          .map((kinds2) => ({
            ...kinds2,
            slug: getSlugForKind(kinds2 as KindExt),
          }))

        const enabledKinds = orderedKinds.filter((kind) => kind.enabled)
        const enabledKindsV2 = orderedKindsV2.filter((kind) => kind.enabled)
        get().save(
          enabledKinds as KindExt[],
          enabledKindsV2 as KindExt[],
          orderedKinds as KindExt[],
          orderedKindsV2 as KindExt[],
        )
      }
    },
    getKindById: (kindId: string) => {
      const orderedKinds = get().orderedKinds

      if (!orderedKinds || !orderedKinds.length) return null

      return orderedKinds.find(
        (kind: KindExt) => parseInt(kind.id, 10) === parseInt(kindId, 10),
      )
    },
    getKindByName: (kindName: string) => {
      const orderedKinds = get().orderedKinds

      if (!orderedKinds || !orderedKinds.length) return null

      return orderedKinds.find((kind: KindExt) => kind.name === kindName)
    },
    getKindsEnabledByName: () => {
      const enabledKinds = get().enabledKinds

      return keyBy(enabledKinds, (kind) => kind.name.toLowerCase() as string)
    },
    getKindsEnabledByNameV2: () => {
      const enabledKindsV2 = get().enabledKindsV2
      return keyBy(enabledKindsV2, (kind) => kind.name.toLowerCase() as string)
    },
    getKindsByName: () => {
      const orderedKinds = get().orderedKinds

      return keyBy(orderedKinds, (kind) => kind.name.toLowerCase() as string)
    },
    orderedKinds: [],
    orderedKindsV2: [],
    refetch: () => {
      return get().fetch()
    },
    save: (
      enabledKinds: KindExt[],
      enabledKindsV2: KindExt[],
      orderedKinds: KindExt[],
      orderedKindsV2: KindExt[],
    ) => {
      if (
        !isEqual(get().enabledKinds, enabledKinds) &&
        !isEqual(get().orderedKinds, orderedKinds) &&
        !isEqual(get().enabledKindsV2, enabledKindsV2) &&
        !isEqual(get().orderedKindsV2, orderedKindsV2)
      ) {
        set({ enabledKinds, orderedKinds, enabledKindsV2, orderedKindsV2 })
      }
    },
  }),
)
