import { defaultIconProps } from 'components/common/icons'
import type { DefaultIconProps } from 'components/common/icons'

export const InstagramIconV2 = ({
  size = defaultIconProps.size,
  className = defaultIconProps.className,
}: DefaultIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={size}
    height={size}
    stroke="currentColor"
    fill="none"
    className={className}>
    <path
      d="M2.50006 12C2.50006 7.52166 2.50006 5.28249 3.8913 3.89124C5.28255 2.5 7.52172 2.5 12.0001 2.5C16.4784 2.5 18.7176 2.5 20.1089 3.89124C21.5001 5.28249 21.5001 7.52166 21.5001 12C21.5001 16.4783 21.5001 18.7175 20.1089 20.1088C18.7176 21.5 16.4784 21.5 12.0001 21.5C7.52172 21.5 5.28255 21.5 3.8913 20.1088C2.50006 18.7175 2.50006 16.4783 2.50006 12Z"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M16.5001 12C16.5001 14.4853 14.4854 16.5 12.0001 16.5C9.51478 16.5 7.50006 14.4853 7.50006 12C7.50006 9.51472 9.51478 7.5 12.0001 7.5C14.4854 7.5 16.5001 9.51472 16.5001 12Z"
      strokeWidth="1.5"
    />
    <path
      d="M17.5078 6.5H17.4988"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
