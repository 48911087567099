import { defaultIconProps } from 'components/common/icons'
import type { DefaultIconProps } from 'components/common/icons'

export const DehazeV2 = ({
  size = defaultIconProps.size,
  className = defaultIconProps.className,
}: DefaultIconProps) => (
  <svg
    // using rem so icon scales with font size
    {...(size ? { width: `${size / 16}rem` } : {})}
    {...(size ? { height: `${size / 16}rem` } : {})}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    fill="currentColor"
    className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 9C7 8.44772 7.44772 8 8 8H20C20.5523 8 21 8.44772 21 9C21 9.55229 20.5523 10 20 10H8C7.44772 10 7 9.55228 7 9Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 16C7 15.4477 7.44772 15 8 15H24C24.5523 15 25 15.4477 25 16C25 16.5523 24.5523 17 24 17H8C7.44772 17 7 16.5523 7 16Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 23C7 22.4477 7.44772 22 8 22H16C16.5523 22 17 22.4477 17 23C17 23.5523 16.5523 24 16 24H8C7.44772 24 7 23.5523 7 23Z"
    />
  </svg>
)
