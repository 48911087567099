import classNames from 'classnames'

interface LayoutContainerProps {
  children: JSX.Element | JSX.Element[]
  className?: string
}

export function LayoutContainer({ children, className }: LayoutContainerProps) {
  return (
    <div
      className={classNames(
        className,
        'mx-auto w-[calc(100%-3rem)] md:max-w-245',
      )}>
      {children}
    </div>
  )
}
