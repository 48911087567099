export { Add } from 'components/common/icons/Add'
export { AngleLeft } from 'components/common/icons/AngleLeft'
export { AngleRight } from 'components/common/icons/AngleRight'
export { AmazonAppStoreBadge } from 'components/common/icons/AmazonAppStoreBadge'
export { AppStoreBadge } from 'components/common/icons/AppStoreBadge'
export { ArrowDropdown } from 'components/common/icons/ArrowDropdown'
export { ArrowRight } from 'components/common/icons/ArrowRight'
export { ArrowUnsorted } from 'components/common/icons/ArrowUnsorted'
export { Bookmark } from 'components/common/icons/Bookmark'
export { BookmarkHollow } from 'components/common/icons/BookmarkHollow'
export { Cancel } from 'components/common/icons/Cancel'
export { Check } from 'components/common/icons/Check'
export { CheckCircle } from 'components/common/icons/CheckCircle'
export { ChevronDown } from 'components/common/icons/ChevronDown'
export { ChevronLeft } from 'components/common/icons/ChevronLeft'
export { ChevronRight } from 'components/common/icons/ChevronRight'
export { ChevronUp } from 'components/common/icons/ChevronUp'
export { Close } from 'components/common/icons/Close'
export { ClosedCaption } from 'components/common/icons/ClosedCaption'
export { CopyClipboard } from 'components/common/icons/CopyClipboard'
export { Dehaze } from 'components/common/icons/Dehaze'
export { DehazeV2 } from 'components/common/icons/DehazeV2'
export { DownIcon } from 'components/common/icons/DownIcon'
export { ErrorFilled } from 'components/common/icons/ErrorFilled'
export { FacebookIcon } from 'components/common/icons/FacebookIcon'
export { FacebookIconV2 } from 'components/common/icons/FacebookIconV2'
export { Favorite } from 'components/common/icons/Favorite'
export { FileAlt } from 'components/common/icons/FileAlt'
export { Filter } from 'components/common/icons/Filter'
export { FormatListBulleted } from 'components/common/icons/FormatListBulleted'
export { Forward10 } from 'components/common/icons/Forward10'
export { Forward30 } from 'components/common/icons/Forward30'
export { ForwardExtendIcon } from 'components/common/icons/ForwardExtendIcon'
export { Fullscreen } from 'components/common/icons/Fullscreen'
export { FullscreenExit } from 'components/common/icons/FullscreenExit'
export { GooglePlayStoreBadge } from 'components/common/icons/GooglePlayStoreBadge'
export { HelpFilled } from 'components/common/icons/HelpFilled'
export { HighlightOff } from 'components/common/icons/HighlightOff'
export { Home } from 'components/common/icons/Home'
export { HooplaColor } from 'components/common/icons/HooplaColor'
export { HooplaColorNoR } from 'components/common/icons/HooplaColorNoR'
export { HooplaWhite } from 'components/common/icons/HooplaWhite'
export { HooplaWhiteNoR } from 'components/common/icons/HooplaWhiteNoR'
export { Hootie } from 'components/common/icons/Hootie'
export { InfoFilled } from 'components/common/icons/infoFilled'
export { InfoOutline } from 'components/common/icons/InfoOutline'
export { InstagramIcon } from 'components/common/icons/InstagramIcon'
export { InstagramIconV2 } from 'components/common/icons/InstagramIconV2'
export { KeyboardArrowLeft } from 'components/common/icons/KeyboardArrowLeft'
export { KeyboardArrowRight } from 'components/common/icons/KeyboardArrowRight'
export { KeyboardBackspace } from 'components/common/icons/KeyboardBackspace'
export { LinkedInIconV2 } from 'components/common/icons/LinkedInIconV2'
export { LinkIcon } from 'components/common/icons/LinkIcon'
export { List } from 'components/common/icons/List'
export { LongArrowsUpAndDown } from 'components/common/icons/LongArrowsUpAndDown'
export { More } from 'components/common/icons/More'
export { Pause } from 'components/common/icons/Pause'
export { Pencil } from 'components/common/icons/Pencil'
export { PlayArrow } from 'components/common/icons/PlayArrow'
export { PauseCircleOutline } from 'components/common/icons/PauseCircleOutline'
export { PlayCircleOutline } from 'components/common/icons/PlayCircleOutline'
export { Previous10 } from 'components/common/icons/Previous10'
export { Remove } from 'components/common/icons/Remove'
export { Repeat } from 'components/common/icons/Repeat'
export { RepeatOne } from 'components/common/icons/RepeatOne'
export { Replay30 } from 'components/common/icons/Replay30'
export { Share } from 'components/common/icons/Share'
export { Search } from 'components/common/icons/Search'
export { SearchV2 } from 'components/common/icons/SearchV2'
export { Settings } from 'components/common/icons/Settings'
export { SettingsV2 } from 'components/common/icons/SettingsV2'
export { Shuffle } from 'components/common/icons/Shuffle'
export { SkipNext } from 'components/common/icons/SkipNext'
export { SkipPrevious } from 'components/common/icons/SkipPrevious'
export { Snooze } from 'components/common/icons/Snooze'
export { Speed } from 'components/common/icons/Speed'
export { Star, StarState } from 'components/common/icons/Star'
export { Thumb } from 'components/common/icons/Thumb'
export { TikTokIconV2 } from 'components/common/icons/TikTokIconV2'
export { TwitterIcon } from 'components/common/icons/TwitterIcon'
export { TwitterIconV2 } from 'components/common/icons/TwitterIconV2'
export { ViewModule } from 'components/common/icons/ViewModule'
export { VisibilityOff } from 'components/common/icons/VisibilityOff'
export { VisibilityOn } from 'components/common/icons/VisibilityOn'
export { Volume } from 'components/common/icons/Volume'
export { YoutubeIcon } from 'components/common/icons/YoutubeIcon'
export { YoutubeIconV2 } from 'components/common/icons/YoutubeIconV2'
export { ZoomIn } from 'components/common/icons/ZoomIn'
export { ZoomOut } from 'components/common/icons/ZoomOut'

export enum IconWeight {
  light = 'LIGHT',
  regular = 'REGULAR',
  heavy = 'HEAVY',
}

export interface DefaultIconProps {
  className?: string
  onClick?: () => void
  size?: number
  title?: string
  weight?: string
}

export const defaultIconProps: DefaultIconProps = {
  className: '',
  onClick: () => {},
  size: 22,
  title: undefined,
  weight: IconWeight.light,
}
