import { Helmet } from 'components/common/Helmet'
import usePage from 'components/hooks/usePage'
import {
  ParameterValue,
  StringParam,
  useQueryParams,
} from 'components/hooks/useQueryParams'
import { KindName } from 'graphql/hoopla/generated/graphql'

const CanonicalLink = () => {
  const [page] = usePage()
  const { pathname, origin } = window.location

  const [{ kindId }] = useQueryParams({ kindId: StringParam })

  const canonicalHref = buildCanonicalHref({ origin, pathname, kindId, page })

  const includeCanonical = shouldIncludeCanonical(pathname)
  const canonicalLink = includeCanonical
    ? [
        {
          rel: 'canonical',
          href: canonicalHref,
        },
      ]
    : []

  return <Helmet link={canonicalLink} />
}

export default CanonicalLink

// Build the canonical URL based on query parameters.
const buildCanonicalHref = ({
  origin,
  pathname,
  kindId,
  page,
}: {
  origin: string
  pathname: string
  kindId: ParameterValue
  page: number
}) => {
  let canonicalHref = `${origin}${pathname}`

  const queryParams = []
  if (kindId) queryParams.push(`kindId=${kindId}`)
  if (page > 1) queryParams.push(`page=${page}`)

  if (queryParams.length) {
    canonicalHref += `?${queryParams.join('&')}`
  }

  return canonicalHref
}

//! Marketing Request: For specified categories,
// only add the canonical tag if the URL ends with an ID.
const shouldIncludeCanonical = (pathname: string): boolean => {
  // Categories that require special canonical handling.
  const categories = Object.values(KindName).map((value) => value.toLowerCase())

  const isRelevantCategory = categories.some((category) =>
    pathname.includes(category),
  )

  // For non-relevant categories, always include canonical.
  if (!isRelevantCategory) {
    return true
  }

  // For relevant categories, only include canonical if the pathname ends with an ID.
  const idRegex = /\d+$/
  return idRegex.test(pathname)
}
