import { defaultIconProps } from 'components/common/icons'
import type { DefaultIconProps } from 'components/common/icons'

export default function BackArrow({
  onClick,
  size = defaultIconProps.size,
  className = defaultIconProps.className,
}: DefaultIconProps) {
  return (
    <svg
      viewBox="5 5 36 36"
      width={size}
      height={size}
      onClick={onClick}
      fill="currentColor"
      stroke="currentColor"
      className={className}>
      <path
        d="M24 40 8 24 24 8 26.1 10.1 13.7 22.5H40V25.5H13.7L26.1 37.9Z"
        stroke="currentColor"
      />
    </svg>
  )
}
