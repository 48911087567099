import { removeControlCharacters } from 'util/input'
import UAParser from 'ua-parser-js'
import { hooplaClient } from 'axiosClients/hooplaAPI'

import type { RegistrationFormData } from 'types/common'

interface ContactUsProps {
  email: string
  libraryId?: number
  name: string
  note: string
}

interface FeedbackDataProps {
  bandwidth?: string | number
  issues: string
  kindId?: number
  note: string
  sapId?: string
  title?: string
}

interface HoldSettingsUpdates {
  autoLendHolds: boolean
  patronId: string
  sendHoldEmail: boolean
  sendHoldPush: boolean
}

interface RegisterUserProps extends RegistrationFormData {
  libraryId: number
}

interface UpdateLibraryProps {
  libraryId?: number
  libraryCard?: string
  libraryPin?: string
}

interface UpdatePasswordProps {
  password: string
  newPassword: string
}

interface UpdatePasswordWithTokenProps {
  password: string
  confirmPassword: string
  token: string
}

export const api = {
  allowEmailConsent: () => hooplaClient.put('/patrons/consents/EMAIL'),
  authenticate: (username: string, password: string) =>
    hooplaClient.post('/tokens', { username, password }),
  contactUs: (contactUsData: ContactUsProps) => {
    return hooplaClient.post('/contact', {
      ...contactUsData,
      mobile: false,
      note: removeControlCharacters(contactUsData.note),
    })
  },
  fetchEmailConsent: () => hooplaClient.get('/patrons/consents/EMAIL'),
  fetchHoldSettings: (hooplaUserId: string, patronId: string) =>
    hooplaClient.get(`/users/${hooplaUserId}/patrons/${patronId}/settings`),
  fetchUser: () => hooplaClient.get('/users'),
  registerUser: (data: RegisterUserProps) => {
    return hooplaClient.post('/v1/register', data, {
      headers: { 'Content-Type': 'application/json' },
    })
  },
  rendezvousAuthorization: (rendezvousCode: string) =>
    hooplaClient.put(`/link/${rendezvousCode}`),
  reportIssue: (feedbackData: FeedbackDataProps) => {
    const parser = new UAParser()

    return hooplaClient.post('/patrons/errors', {
      bandwidth: feedbackData.bandwidth,
      deviceModel: parser.getBrowser().name,
      deviceVersion: parser.getBrowser().version,
      hooplaVersion: process.env.REACT_APP_VERSION,
      issues: feedbackData.issues,
      kindId: feedbackData.kindId,
      note: removeControlCharacters(feedbackData.note),
      os: parser.getOS().name,
      osVersion: parser.getOS().version,
      sapId: feedbackData.sapId,
      title: feedbackData.title,
    })
  },
  resetPassword: (email: string) =>
    hooplaClient.delete(`/v2/users/password/reset?email=${email}`),
  revokeEmailConsent: () => hooplaClient.delete('/patrons/consents/EMAIL'),
  sendKidsModePin: (pin: number) => {
    return hooplaClient.post(
      '/v2/users/pin',
      { pin },
      { headers: { 'Content-Type': 'application/json' } },
    )
  },
  updateEmail: (email: string) =>
    hooplaClient.put(
      '/users/email',
      { email },
      { headers: { 'Content-Type': 'application/json' } },
    ),
  updateHoldSettings: (
    hooplaUserId: string,
    patronId: string,
    updates: HoldSettingsUpdates,
  ) => {
    return hooplaClient.put(
      `/users/${hooplaUserId}/patrons/${patronId}/settings`,
      updates,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
  },
  updateLibrary: (updates: UpdateLibraryProps) => {
    return hooplaClient.put(`/patrons`, updates, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
  },
  updatePassword: (data: UpdatePasswordProps) => {
    return hooplaClient.put('/users/password', data, {
      headers: { 'Content-Type': 'application/json' },
    })
  },
  updatePasswordWithToken: (data: UpdatePasswordWithTokenProps) => {
    return hooplaClient.put('/v2/users/password', data, {
      headers: { 'Content-Type': 'application/json' },
    })
  },
}
