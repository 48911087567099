import { isLoggedIn } from 'util/user'
import { defineMessages, useIntl } from 'react-intl'
import { useKidsMode } from 'state/useKidsMode'
import { FC, useCallback } from 'react'
import KidsModeButtonUI from '../KidsModeButtonUI'

type KidsModeButtonProps = {
  className?: string
}

const messages: IntlMessage = defineMessages({
  label: {
    id: 'settings.label.kids_mode',
    defaultMessage: 'Kids Mode',
  },
})

/**
 * [Container component](https://www.patterns.dev/react/presentational-container-pattern#container-components)
 * that contains the logic for the Kids Mode toggle button / link. It should pass relevant
 * props to a single presentational component, which will handle rendering the appropriate UI.
 *
 *
 * *(Note: it's an awkward user experience to have an interactive element that's sometimes
 * a toggle button and sometimes a link. This should be addressed at some point in the future.)*
 */
const KidsModeButton: FC<KidsModeButtonProps> = ({ className }) => {
  const isKidsMode = useKidsMode((state) => state.isKidsMode)
  const setKidsMode = useKidsMode((state) => state.setKidsMode)

  const { formatMessage } = useIntl()
  const isAuthenticated = isLoggedIn()
  const toggleKidsMode = useCallback(() => {
    setKidsMode({ isKidsMode: !isKidsMode })
  }, [setKidsMode, isKidsMode])

  const ariaLabel = formatMessage(messages.label)

  return (
    <KidsModeButtonUI
      ariaLabel={ariaLabel}
      className={className}
      isAuthenticated={isAuthenticated}
      isKidsMode={isKidsMode}
      toggleKidsMode={toggleKidsMode}
    />
  )
}

export default KidsModeButton
