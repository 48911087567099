import { getDeviceId } from 'util/user'
import { useLazyQuery } from '@apollo/client'
import {
  HootieInitializePayload,
  HootieMessage,
  HootieState,
} from 'components/hootie/types'
import { useHootieEvents } from 'components/hooks/analytics/useHootieEvents'
import { useHootieState } from 'components/hootie/useHootieState'
import shallow from 'zustand/shallow'
import { TitleTeaser } from 'types'
import {
  GetInitializeHootieQueryDocument,
  GetPromptHootieQueryDocument,
  GetTitleTeaserQueryDocument,
} from 'graphql/hootie/generated/graphql'

import { Status } from 'types/hoopla'

// ALL QUERIES SHOULD ONLY BE CALLING THE GQL FOR HOOTIE AND NOT ANY OTHER QUERIES OR GATEWAYS
// This is due to the backend architecture not wanting the main service to go down due to rouge actors, etc..
export default function useHootie(): HootieState {
  const { sendHootieResponseEvent } = useHootieEvents()
  const [initializeHootieQuery] = useLazyQuery<{
    initializeHootieSession: HootieInitializePayload
  }>(GetInitializeHootieQueryDocument)
  const [promptHootieQuery] = useLazyQuery<{ promptHootie: HootieMessage }>(
    GetPromptHootieQueryDocument,
  )
  const [titleTeaserQuery] = useLazyQuery<{ titleTeaser: TitleTeaser }>(
    GetTitleTeaserQueryDocument,
  )

  const {
    clickedTitleId,
    error,
    isOpen,
    isFirstOpen,
    loading,
    responses,
    scrollPosition,
    fromResume,
    state,
    success,
    setClickedTitleId,
    setError,
    setIsOpen,
    setFirstOpen,
    setLoading,
    setResponses,
    setFromResume,
    setScrollPosition,
    setState,
    setSuccess,
  } = useHootieState((hootieState) => ({ ...hootieState }), shallow)

  const appendMessage = (newResponse: HootieMessage) => {
    setResponses([...responses, newResponse])
  }

  const initialize = async () => {
    setLoading(true)

    const { data, error, loading } = await initializeHootieQuery({
      variables: { input: { deviceId: getDeviceId() } },
      context: { clientName: 'HOOTIE' },
      fetchPolicy: 'cache-and-network',
    })

    setLoading(loading)
    setError(error)

    if (data) {
      appendMessage({
        ...data.initializeHootieSession,
        titles: [],
        source: '',
      })

      setState(data.initializeHootieSession.state)
    }
  }

  const getCaseId = (titleId: string): string => {
    const caseId = responses.find((hootieMessage) =>
      hootieMessage.titles.map((title) => title.teaser.id).includes(titleId),
    )?.caseId

    return caseId ?? ''
  }

  const getEventSource = (titleId: string): string => {
    const eventSource = responses.find((hootieMessage) =>
      hootieMessage.titles.map((title) => title.teaser.id).includes(titleId),
    )?.source

    return eventSource ?? ''
  }

  const promptHootie = async (query: string) => {
    setSuccess(false)

    appendMessage({
      caseId: '',
      state: '',
      source: '',
      titles: [],
      query,
    })
    setLoading(true)

    const { data, error, loading } = await promptHootieQuery({
      variables: {
        input: { deviceId: getDeviceId(), statement: query, state },
      },
      context: { clientName: 'HOOTIE' },
      fetchPolicy: 'cache-and-network',
    })

    setLoading(loading)
    if (!error) {
      setSuccess(true)
    }

    if (data) {
      appendMessage({
        ...data.promptHootie,
        query,
      })

      sendHootieResponseEvent(
        {
          bottomMessage: data.promptHootie.bottomMessage,
          caseId: data.promptHootie.caseId,
          titleList: data.promptHootie.titles.map((title) => ({
            holdPosition: [Status.Hold, Status.Held].includes(
              title.teaser.status,
            )
              ? title.teaser.holdsPerCopy
              : undefined,
            kind: title.teaser.kind.name,
            licenseType: title.teaser.licenseType,
            id: title.teaser.id,
            status: title.teaser.status,
            type: 'title',
          })),
          topMessage: data.promptHootie.topMessage,
        },
        data.promptHootie.source,
      )

      setState(data.promptHootie.state)
    }
  }

  // updates the status via message in the current hootie response in place.
  const updateHootieTitleFavorite = (titleId: string) => {
    const updatedResponses = responses.map((currentResponse) => ({
      ...currentResponse,
      titles: currentResponse?.titles?.map((promptHootieResponseTitle) => ({
        ...promptHootieResponseTitle,
        teaser:
          promptHootieResponseTitle.teaser.id === titleId
            ? {
                ...promptHootieResponseTitle.teaser,
                isFavorite: !promptHootieResponseTitle.teaser.isFavorite,
              }
            : promptHootieResponseTitle.teaser,
      })),
    }))

    setResponses(updatedResponses)
  }

  // updates the status via message in the current hootie response in place.
  const updateHootieTitleStatus = async (
    titleId: string,
    newStatus: Status,
  ) => {
    if (!responses.length) return
    if (!titleId) return

    const refreshTeaser = async (titleId: string) => {
      const { data, error, loading } = await titleTeaserQuery({
        variables: { input: { id: titleId } },
        context: { clientName: 'HOOTIE' },
        fetchPolicy: 'cache-and-network',
      })

      if (data) {
        return data.titleTeaser
      }

      setError(error)
      setLoading(loading)
    }

    const refreshedTeaser = await refreshTeaser(titleId)
    const updatedResponses = responses.map((currentResponse) => ({
      ...currentResponse,
      titles: currentResponse?.titles?.map((promptHootieResponseTitle) => ({
        ...promptHootieResponseTitle,
        status: newStatus,
        teaser:
          promptHootieResponseTitle?.teaser?.id === titleId
            ? {
                ...promptHootieResponseTitle?.teaser,
                ...refreshedTeaser,
              }
            : promptHootieResponseTitle?.teaser,
      })),
    }))

    setResponses(updatedResponses)
  }

  const updateHootieToResume = (titleId: string, position: number) => {
    if (!responses.length) return
    if (!titleId) return
    setFromResume(true)

    const updatedResponses = responses.map((currentResponse) => ({
      ...currentResponse,
      titles: currentResponse?.titles?.map((promptHootieResponseTitle) => ({
        ...promptHootieResponseTitle,
        teaser:
          promptHootieResponseTitle.teaser.id === titleId
            ? {
                ...promptHootieResponseTitle.teaser,
                playbackPosition: {
                  ...promptHootieResponseTitle.teaser.playbackPosition,
                  percentComplete: position > 1 ? position : 0,
                },
              }
            : promptHootieResponseTitle.teaser,
      })),
    }))

    setResponses(updatedResponses)
  }

  const updateHootieCount = (titleId: string, newRating: number) => {
    const updatedResponses = responses.map((currentResponse) => ({
      ...currentResponse,
      titles: currentResponse?.titles?.map((promptHootieResponseTitle) => ({
        ...promptHootieResponseTitle,
        teaser:
          promptHootieResponseTitle.teaser.id === titleId
            ? {
                ...promptHootieResponseTitle.teaser,
                titleRating: {
                  ...promptHootieResponseTitle.teaser.titleRating,
                  totalCount: newRating,
                },
              }
            : promptHootieResponseTitle.teaser,
      })),
    }))

    setResponses(updatedResponses)
  }

  const updateHootieTVEpisodeStatus = (
    titleId: string,
    episodeId: string,
    newStatus: Status,
  ) => {
    const updatedResponses = responses.map((currentResponse) => ({
      ...currentResponse,
      titles: currentResponse?.titles?.map((promptHootieResponseTitle) => ({
        ...promptHootieResponseTitle,
        teaser:
          promptHootieResponseTitle.teaser.id === titleId
            ? {
                ...promptHootieResponseTitle.teaser,
                episodes: promptHootieResponseTitle.teaser.episodes.map(
                  (episode) => {
                    if (episode.id === episodeId) {
                      return { ...episode, status: newStatus }
                    }
                    return episode
                  },
                ),
              }
            : promptHootieResponseTitle.teaser,
      })),
    }))

    setResponses(updatedResponses)
  }

  const getIsHootie = (titleId: string) => {
    const messagesContainsTitleId = responses
      .map((hootieMessage) => hootieMessage.titles)
      .flat()
      .map((title) => title.teaser.id)
      .includes(titleId)

    return messagesContainsTitleId
  }

  const updateHootieFeedback = (
    caseId: string,
    userApprovesResults: boolean,
  ) => {
    const newResponses = responses.map((hootieMessage) => {
      if (hootieMessage.caseId === caseId) {
        return {
          ...hootieMessage,
          userApprovesResults,
        }
      }
      return hootieMessage
    })
    setResponses(newResponses)
  }

  return {
    clickedTitleId,
    error,
    getCaseId,
    getEventSource,
    getIsHootie,
    initialize,
    isOpen,
    isFirstOpen,
    fromResume,
    loading,
    promptHootie,
    responses,
    scrollPosition,
    setClickedTitleId,
    setIsOpen,
    setFirstOpen,
    setScrollPosition,
    setFromResume,
    success,
    updateHootieCount,
    updateHootieToResume,
    updateHootieFeedback,
    updateHootieTitleFavorite,
    updateHootieTitleStatus,
    updateHootieTVEpisodeStatus,
  }
}
