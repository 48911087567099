import parser from 'ua-parser-js'
import includes from 'lodash/includes'

export const browserAllowed = (customBrowserList) => {
  const deviceInfo = parser()
  const { browser } = deviceInfo
  const allowedBrowsers = customBrowserList || [
    'Chrome',
    'Edge',
    'Firefox',
    'Safari',
  ]

  return allowedBrowsers.indexOf(browser.name) !== -1
}

const browserPlaybackSupported = () => {
  const deviceInfo = parser()
  const { name, major } = deviceInfo.browser

  // this could be simplified but I'll leave it like this so we can add further browser/version combos in the future
  if (name === 'Firefox' && parseInt(major, 10) < 48) {
    return false
  }

  return true
}

export const canPlayback = () =>
  isSupported() &&
  !isDevice() &&
  !isPlatformMobile.any() &&
  !isSmartTv.any() &&
  browserPlaybackSupported()

export const isSupported = () => {
  const deviceInfo = parser()
  // The Internet browser on samsung devices is not properly parsed by our UA Parser.
  // https://github.com/faisalman/ua-parser-js/issues/168
  // for now we are going to try and detect it manually.
  if (includes(navigator.userAgent, 'SamsungBrowser')) {
    return false
  }

  // block any browser running on Mac OS < 10.6
  if (deviceInfo.os.name === 'Mac OS') {
    const versionArray = deviceInfo.os.version.split('.')
    if (versionArray[0] <= 10 && versionArray[1] <= 6) {
      return false
    }
  }
  // we are excluding facebook in-app browser from the supported list. https://midwesttapes.atlassian.net/browse/WEB-2454
  if (includes(navigator.userAgent, 'Facebook')) {
    return false
  }

  return browserAllowed([
    'Chrome',
    'Edge',
    'Firefox',
    'Mobile Safari',
    'Safari',
    'Silk',
    'PhantomJS',
    'WebKit',
    'Chrome Headless',
  ])
}

export const isDevice = () => {
  const deviceInfo = parser()
  const device = deviceInfo.device.type
  // an xbox does not have a device type but we want to treat it like one
  return (
    device != null ||
    includes(navigator.userAgent, 'Xbox') ||
    deviceInfo.browser.name === 'Silk' ||
    isTablet()
  )
}

export const isWindows = () => {
  const deviceInfo = parser()
  return deviceInfo.os.name === 'Windows'
}

export const isChrome = () => {
  const deviceInfo = parser()
  return deviceInfo.browser.name === 'Chrome'
}

export const isSafari = () => {
  const deviceInfo = parser()
  return (
    deviceInfo.browser.name === 'Safari' ||
    deviceInfo.browser.name === 'Mobile Safari'
  )
}

export const isFirefox = () => {
  const deviceInfo = parser()
  return deviceInfo.browser.name === 'Firefox'
}

export const isMobile = () => {
  // matches a breakpoint in our tailwind theme
  return window.innerWidth <= 900
}

export const isTablet = () => {
  const deviceInfo = parser()
  return (
    deviceInfo.device?.type === 'tablet' ||
    (navigator.userAgent.match(/Mac/) &&
      navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2)
  )
}

export const isPlatformMobile = {
  Android: () => {
    return navigator.userAgent.match(/Android/i) === null ? false : true
  },
  iOS: () => {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i) === null
      ? false
      : true
  },
  Opera: () => {
    return navigator.userAgent.match(/Opera Mini/i) === null ? false : true
  },
  Windows: () => {
    return navigator.userAgent.match(/IEMobile/i) === null ? false : true
  },
  any: () => {
    return (navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/iPhone|iPad|iPod/i) ||
      navigator.userAgent.match(/Opera Mini/i) ||
      navigator.userAgent.match(/IEMobile/i)) === null
      ? false
      : true
  },
}

export const isSmartTv = {
  any: () => {
    return (navigator.userAgent.match(/NetCast/i) ||
      navigator.userAgent.match(/SmartTV/i)) === null
      ? false
      : true
  },
}
