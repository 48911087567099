import { defaultIconProps } from 'components/common/icons'
import type { DefaultIconProps } from 'components/common/icons'

export const SearchV2 = ({
  size = defaultIconProps.size,
  className = defaultIconProps.className,
}: DefaultIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={size}
    height={size}
    fill="currentColor"
    className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2929 15.2929C15.6834 14.9024 16.3166 14.9024 16.7071 15.2929L20.7071 19.2929C21.0976 19.6834 21.0976 20.3166 20.7071 20.7071C20.3166 21.0976 19.6834 21.0976 19.2929 20.7071L15.2929 16.7071C14.9024 16.3166 14.9024 15.6834 15.2929 15.2929Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58173 19 11C19 15.4183 15.4183 19 11 19C6.58173 19 3 15.4183 3 11ZM11 5C7.68629 5 5 7.68629 5 11C5 14.3137 7.68629 17 11 17C14.3137 17 17 14.3137 17 11C17 7.68629 14.3137 5 11 5Z"
    />
  </svg>
)
