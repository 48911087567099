import { isLoggedIn } from 'util/user'
import { useLibrary } from 'state/useLibrary'
import { useMemo } from 'react'

// 419 is Latin America
const LOCALE_NOT_AZ: string[] = ['US', 'CA', '419']

const locale: string = navigator.languages
  ? navigator.languages[0]
  : navigator.language
const country: string =
  locale && locale.split('-').length > 1 ? locale.split('-')[1] : 'US'

/**
 * This hook determines if the user is in Australia or New Zealand.
 * It checks the user's library country if authenticated,
 * otherwise it checks the URL or the browser locale.
 * @returns `true` if the user is in Australia or New Zealand
 */
function useIsAUNZ(): boolean {
  const library = useLibrary((state) => state.library)
  const isAuthenticated: boolean = isLoggedIn()

  const isAUNZDomain = (): boolean => {
    const hostname = new URL(window.location.href).hostname
    return hostname.endsWith('.au') || hostname.endsWith('.nz')
  }

  return useMemo(() => {
    const isAUNZLibrary = (): boolean => {
      return library?.country === 'AU' || library?.country === 'NZ'
    }

    if (isAuthenticated && library) {
      return isAUNZLibrary()
    }

    if (isAUNZDomain()) {
      return true
    }

    return LOCALE_NOT_AZ.indexOf(country) === -1
  }, [isAuthenticated, library])
}

export default useIsAUNZ
